import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from '../types/RequestStatus';
import User, { UserRole } from '../types/User';

interface AuthState {
  user: User | null;
  requestStatus: RequestStatus
}

const initialState: AuthState = {
  user: null,
  requestStatus: RequestStatus.Idle
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequestStatus(state, action: PayloadAction<{ requestStatus: RequestStatus }>) {
      state.requestStatus = action.payload.requestStatus
    },
    registrationRequestStatus(state, action: PayloadAction<{ requestStatus: RequestStatus }>) {
      state.requestStatus = action.payload.requestStatus
    },

    setUser(state, action: PayloadAction<{ user: User | null }>) {
      state.user = action.payload.user;
    },

  },
});

export const { setUser, loginRequestStatus } = authSlice.actions;

export default authSlice.reducer;
