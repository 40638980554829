import React from 'react';
import LoginSignUpForm from '../../components/LoginSignUpForm';

function LoginPage() {
  
  return (
    <LoginSignUpForm isLoginPage  />
  );
}

export default LoginPage;
