import React, { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';

// Функція для завантаження файлів
export const uploadFile = (file: File, options: { onProgress?: (progress: number) => void } = {}, backetPath = 'files/'): Promise<string> => {
  const storage = getStorage();
  const fileRef = ref(storage, backetPath + file.name);

  return new Promise<string>((resolve, reject) => {
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (options.onProgress) {
          options.onProgress(progress);
        }
      },
      (error) => {
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        }).catch((error) => {
          reject(error);
        });
      }
    );
  });
};

interface WordFormProps {
  addCategory: (category: string, categoryAudioUrl: string, words: { word: string, audioUrl: string }[]) => void;
}

const AddWordsWithCateggory: React.FC<WordFormProps> = ({ addCategory }) => {
  const [category, setCategory] = useState<string>('');
  const [categoryAudioUrl, setCategoryAudioUrl] = useState<string>('');
  const [words, setWords] = useState<{ word: string, audioUrl: string }[]>([]);
  const [word, setWord] = useState<string>('');
  const [wordAudioUrl, setWordAudioUrl] = useState<string>('');
  const [showCategoryAudio, setShowCategoryAudio] = useState<boolean>(false);
  const [showWordAudio, setShowWordAudio] = useState<boolean>(false); // Для слова

  const handleAddWord = () => {
    setWords([...words, { word, audioUrl: wordAudioUrl }]);
    setWord('');
    setWordAudioUrl('');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addCategory(category, categoryAudioUrl, words);
    setCategory('');
    setCategoryAudioUrl('');
    setWords([]);
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>, setAudioUrl: (url: string) => void) => {
    const file = e.target.files?.[0];
    if (file) {
      const downloadURL = await uploadFile(file);
      setAudioUrl(downloadURL);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='flex flex-col gap-5 my-7'>
        {/* Поле для категорії */}
        <div className='flex flex-col'>
          <label>Група:</label>
          <input
            className='border-none outline-none p-2 rounded-md'
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>

        {/* Чекбокс для показу поля аудіо для категорії */}
        <div className='flex items-center'>
          <input
            type="checkbox"
            checked={showCategoryAudio}
            onChange={() => setShowCategoryAudio(!showCategoryAudio)}
          />
          <label className='ml-2'>Додати аудіо URL для категорії</label>
        </div>

        {showCategoryAudio && (
          <div className='flex flex-col'>
            <label>Аудіо для категорії:</label>
            <input
              type="file"
              onChange={(e) => handleFileUpload(e, setCategoryAudioUrl)}
            />
          </div>
        )}

        {/* Поле для слова */}
        <div className='flex flex-col'>
          <label>Слово:</label>
          <input
            className='border-none outline-none p-2 rounded-md'
            type="text"
            value={word}
            onChange={(e) => setWord(e.target.value)}
          />
        </div>

        {/* Чекбокс для показу поля аудіо для слова */}
        <div className='flex items-center'>
          <input
            type="checkbox"
            checked={showWordAudio}
            onChange={() => setShowWordAudio(!showWordAudio)}
          />
          <label className='ml-2'>Додати аудіо URL для слова</label>
        </div>

        {showWordAudio && (
          <div className='flex flex-col'>
            <label>Аудіо для слова:</label>
            <input
              type="file"
              onChange={(e) => handleFileUpload(e, setWordAudioUrl)}
            />
          </div>
        )}
      </div>
      <div className='flex gap-4'>
        <button className='bg-white rounded-md p-2' type="button" onClick={handleAddWord}>Add Word</button>
        {words.map((i, index) => (
          <span key={index}>{i.word}</span>
        ))}
        <button className='bg-white/60 rounded-md p-2' type="submit">Add Category</button>
      </div>
    </form>
  );
};

export default AddWordsWithCateggory;
