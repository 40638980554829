import { all, call, put, takeLatest } from 'redux-saga/effects';
import { addDoc, collection, doc, getDoc, getDocs, updateDoc, DocumentSnapshot, QuerySnapshot, arrayUnion } from 'firebase/firestore';
import { firestore } from '../firebase';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  setCourse,
  fetchCourseById,
  fetchCourses,
  setSelectedCourse,
  addTopic,
  addCourse,
  setTopic,
  setSubTopic,
  addSubTopic,
} from '../actions/courseActions';

interface Course {
  id: string;
  name: string;
  imageUrl: string;
  topics?: Topic[];
}

interface Topic {
  id: string;
  name: string;
  subtopics?: Subtopic[];
}

interface Subtopic {
  id: string;
  name: string;
}

function* fetchCoursesSaga() {
  try {
    const coursesCollection = collection(firestore, 'courses');
    const coursesSnapshot: QuerySnapshot = yield call(getDocs, coursesCollection);


    const courses = coursesSnapshot.docs.map(doc => put(setCourse({
      id: doc.id,
      ...doc.data()
    })));

    yield all(courses);
  } catch (error: any) {
    console.error('Error fetching courses:', error);
  }
}

function* fetchCourseByIdSaga(action: PayloadAction<string>): Generator<any, void, DocumentSnapshot<any>> {


  try {
    const courseId = action.payload;
    const courseDoc: DocumentSnapshot<any> = yield call(getDoc, doc(firestore, 'courses', courseId));



    if (courseDoc.exists()) {
      const courseData = courseDoc.data();
      const course: any = {
        id: courseDoc.id,
        name: courseData.name,
        imageUrl: courseData.imageUrl,
        topics: courseData.topics || [],
        free: courseData?.free || null
      };

      yield put(setSelectedCourse(course));
    } else {
      console.error('Course not found in database');
    }
  } catch (error: any) {
    console.error('Error fetching course:', error);
  }
}

function* addCourseSaga(action: PayloadAction<{ name: string; imageUrl: string }>): Generator<any, void, any> {
  try {
    const { name, imageUrl } = action.payload;
    const newCourse = { name, imageUrl, topics: [] };
    const courseRef = yield call(addDoc, collection(firestore, 'courses'), newCourse);

    yield put(setCourse({ id: courseRef.id, name, imageUrl }));
  } catch (error: any) {
    console.error('Error adding course:', error);
  }
}

function* addTopicSaga(action: PayloadAction<{ courseId: string; topicName: string }>): Generator<any, void, any> {
  try {
    const { courseId, topicName } = action.payload;
    const courseDocRef = doc(firestore, 'courses', courseId);
    const courseDoc: DocumentSnapshot<any> = yield call(getDoc, courseDocRef);

    if (courseDoc.exists()) {
      const topic = { id: `${Date.now()}`, name: topicName, subtopics: [] };
      yield call(() => updateDoc(courseDocRef, { topics: arrayUnion(topic) }));
      yield put(setTopic(topic));
    } else {
      console.error('Course not found in database');
    }
  } catch (error: any) {
    console.error('Error adding topic:', error);
  }
}

function* addSubtopicSaga(action: PayloadAction<{ courseId: string; id: string; subTopicName: string; imgUrl: string }>): Generator<any, void, any> {
  try {
    const { courseId, id, subTopicName, imgUrl } = action.payload;
    const courseDocRef = doc(firestore, 'courses', courseId);
    const courseDoc: DocumentSnapshot<any> = yield call(getDoc, courseDocRef);

    if (courseDoc.exists()) {
      const courseData = courseDoc.data();
      const topics = courseData.topics || [];
      const topicIndex = topics.findIndex((topic: any) => topic.id === id);

      if (topicIndex !== -1) {
        const subtopic = { id: `${Date.now()}`, name: subTopicName, imgUrl };
        const updatedSubtopics = [...topics[topicIndex].subtopics, subtopic];
        topics[topicIndex].subtopics = updatedSubtopics;

        yield call(() => updateDoc(courseDocRef, { topics }));
        yield put(setSubTopic({ topicId: id, subtopic }));
      } else {
        console.error('Topic not found in course');
      }
    } else {
      console.error('Course not found in database');
    }
  } catch (error: any) {
    console.error('Error adding subtopic:', error);
  }
}


function* courseSaga() {
  yield takeLatest(fetchCourses.type, fetchCoursesSaga);
  yield takeLatest(addCourse.type, addCourseSaga);
  yield takeLatest(fetchCourseById.type, fetchCourseByIdSaga);
  yield takeLatest(addTopic.type, addTopicSaga);
  yield takeLatest(addSubTopic.type, addSubtopicSaga);
}

export default courseSaga;
