import { createAction } from '@reduxjs/toolkit';

export const fetchCourses = createAction('courses/fetchCourses');
export const fetchCourseById = createAction<string>('courses/fetchCourseById');
export const addCourse = createAction<{ name: string; imageUrl: string }>('courses/addCourse');
export const setCourse = createAction<any>('courses/setCourse');
export const setSelectedCourse = createAction<{ course: any | null }>('courses/setSelectedCourse');
export const addTopic = createAction<{ courseId: string; topicName: string }>('courses/addTopic');
export const setTopic = createAction<any>('courses/setTopic');
export const addSubTopic = createAction<{ courseId: string; id: string; subTopicName: string, imgUrl: string; }>('courses/addSubTopic');
export const setSubTopic = createAction<{ topicId: string, subtopic: any }>('courses/setSubTopic');



