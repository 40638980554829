import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';

interface UploadFileOptions {
  onProgress?: (progress: number) => void;
}

export const uploadFile = (file: File, options: UploadFileOptions = {}, backetPath ='files/'): Promise<string> => {
  const storage = getStorage();
  const fileRef = ref(storage, backetPath + file.name); 

  return new Promise<string>((resolve, reject) => {
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (options.onProgress) {
          options.onProgress(progress);
        }
      },
      (error) => {
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        }).catch((error) => {
          reject(error);
        });
      }
    );
  });
};
