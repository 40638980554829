import React from 'react';

const CourseBenefitsList = () => {
  // Масив з пунктами списку
  const benefits = [
    'Починаєш вчити польську мову',
    'Не любиш нудних занять',
    'Маєш не багато часу, а польська терміново потрібна',
    'Хочеш почувати себе нормальною людиною в Польщі',
    'Хочеш заговорити польською',
    'Трошки говориш/розумієш польською, але плутаєш закінчення і не вистачає слів',
    'Не можеш висловитися польською',
  ];

  return (
    <div className="rounded-lg  mx-auto mb-4">
      <h2 className="text-xl lg:text-2xl font-bold mb-4">Цей курс для тебе, якщо:</h2>
      <ul className="flex flex-col justify-center">
        {benefits.map((benefit, index) => (
          <li key={index} className="flex text-base lg:text-lg  items-start w-full p-1">
            <svg
              className="w-6 h-6 text-green-500 flex-shrink-0 mr-3 mt-[2px]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-10.707a1 1 0 00-1.414 0L9 11.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 000-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className='text-start'>
              {benefit}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CourseBenefitsList;
