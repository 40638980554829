import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from '../types/RequestStatus';

interface AuthState {
  courses: any[];
  requestStatus: RequestStatus;
  selectedCourse: any | null;
}

const initialState: AuthState = {
  courses: [],
  requestStatus: RequestStatus.Idle,
  selectedCourse: null,
};

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    coursesRequestStatus(state, action: PayloadAction<{ requestStatus: RequestStatus }>) {
      state.requestStatus = action.payload.requestStatus;
    },

    setCourse(state, action: PayloadAction<{ id: string; name: string; imageUrl: string; topics?: any[] }>) {
      if (action.payload) {
        state.courses.push(action.payload);
      }
    },

    setSelectedCourse(state, action: PayloadAction<{ course: any | null }>) {
      if (action.payload) {
        state.selectedCourse = action.payload;
      }
    },

    setTopic(state, action: PayloadAction<any>) {
      state.selectedCourse.topics = [...state.selectedCourse.topics, action.payload];
    },

    setSubTopic(state, action: PayloadAction<{ topicId: string; subtopic: any }>) {
      const { topicId, subtopic } = action.payload;
      state.selectedCourse?.topics.find((topic: any) => topic.id === topicId)?.subtopics.push(subtopic)
      
    },
  },
});

export const {
  coursesRequestStatus,
  setCourse,
  setSelectedCourse,
  setSubTopic,
  setTopic
} = coursesSlice.actions;

export default coursesSlice.reducer;
