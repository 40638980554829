import React, { useEffect } from 'react';
import './App.css';
import MainLayout from './layouts/MainLayout';
import Header from './components/Header';
import { Route, Routes } from 'react-router-dom'
import MainPage from './pages/MainPage';
import ContactPage from './pages/ContactPage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import Courses from './pages/Courses';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { init } from './actions/authActions';
import Course from './pages/Course';
import Lesson from './pages/Lesson';
import { pdfjs } from 'react-pdf';
import AdminPanel from './pages/AdminPanel';
import PaymentPage from './pages/PaymentPage';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Pricing from './pages/Pricing';
import SuccessfulPayment from './pages/SuccessfulPayment';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();


function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(init());
  }, []);

  return (
    <MainLayout>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/about" element={<ContactPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/:courseId" element={<Course />} />
        <Route path="/adminPanel" element={<AdminPanel />} />
        <Route path="/paymentPage" element={<Pricing />} />
        <Route path="/successfulpayment" element={<SuccessfulPayment />} />
        <Route path="/courses/:courseId/:lessonId" element={<Lesson />} />
      </Routes>
    </MainLayout>
  );
}

export default App;

