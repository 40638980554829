import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from '../../types/User';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const PricingSection = () => {
  const plans = [
    {
      name: 'СТАНДАРТ КУРС',
      oldPrice: '249 зл',
      price: '199 зл',
      description: 'Якщо в тебе дуже мало часу і ти не любиш нудних уроків, то цей курс саме для тебе!',
      features: [
        '20 базових тем в польській мові (гармонійно поєднана лексика, граматика та письмо)',
        '60 сучасних відео уроків',
        'До кожного уроку цікаві, продумані завдання на інтерактивній платформі',
        'Багато аудіо завдань з носіями мови',
        'Доступ до курсу – чотири місяці',
      ],
      type: 'standard',
    },
    {
      name: 'ПРЕМІУМ КУРС',
      oldPrice: '590 зл',
      price: '540 зл',
      description: 'Якщо тобі терміново потрібна польська, маєш час, щоб попрактикуватися і не любиш нудних уроків, то цей курс саме для тебе!',
      features: [
        '20 базових тем в польській мові (гармонійно поєднана лексика, граматика та письмо)',
        '60 сучасних відео уроків',
        'До кожного уроку цікаві, продумані завдання на інтерактивній платформі',
        'Багато аудіо завдань з носіями мови',
        "Доступ до курсу – п'ять місяців",
        '11 розмовних занять 75хв (1 раз в тиждень в скайпі) в міні групі до 3-5 осіб де можна задати будь які запитання і 100% часу – практика.',
        'Старт навчання: 17 листопада. Платформа доступна одразу після оплати.'
      ],
      detailed: 'Всі розмовні заняття записуються і можна їх переглянути пізніше. Час розмовних занять: Група 1: неділя 10:00, Група 2 понеділок 18:20, Група 3 вівторок 10:00',
      type: 'premium',
      subtypes: [
        {
          type: 'premium1',
          name: 'Група 1 неділя 10:00'
        },
        {
          type: 'premium2',
          name: 'Група 2 понеділок 18:20'
        },
        {
          type: 'premium3',
          name: 'Група 3 вівторок 10:00'
        },
      ],
    },
    {
      name: 'VIP КУРС',
      oldPrice: '990 зл',
      price: '940 зл',
      description: 'Якщо маєш час, щоб попрактикуватися і не любиш нудних уроків, то цей курс саме для тебе!',
      features: [
        '20 базових тем в польській мові (гармонійно поєднана лексика, граматика та письмо)',
        '60 сучасних відео уроків',
        'До кожного уроку цікаві, продумані завдання на інтерактивній платформі',
        'Багато аудіо завдань з носіями мови',
        'Доступ до курсу – пів року',
        '21 розмовних заняття 75хв (2 рази в тиждень в скайпі) в міні групі до 3-5 осіб де можна задати будь які запитання і 100% часу – практика.',
        'Старт навчання: 17 листопада. Платформа доступна одразу після оплати.'
      ],
      detailed: 'Всі розмовні заняття записуються і можна їх переглянути пізніше. Група 1: середа 18:20 і неділя 11:20, Група 2 четвер 10:00 і неділя 12:40',
      type: 'vip',
      subtypes: [
        {
          type: 'vip1',
          name: 'Група 1: середа 18:20 і неділя 11:20'
        },
        {
          type: 'vip2',
          name: 'Група 2 четвер 10:00 і неділя 12:40'
        }   
      ],
    },
  ];

  const stripePromise = loadStripe('pk_live_51Q9tdiGIuZv5gvtEOUVKYMHMiJhH7C499sDYfkMfyE5XOIGlgrAJsZIrJCnk12M1efvNattsQph9g0jp2MPquUx200bUL5TmB9');
  const [expandedPlan, setExpandedPlan] = useState<number | null>(null);

  const toggleDetails = (index: number | null) => {
    setExpandedPlan(expandedPlan === index ? null : index);
  };
  const user = useSelector((state: any) => state.auth.user);
  const navigate = useNavigate();
  const handleCheckout = async (productType: string) => {
    if (user) {
      try {
        const response = await fetch('https://us-central1-test-course-88f8a.cloudfunctions.net/createCheckoutSession', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            productType: productType,
            email: user.email,
          }),
        });

        const { id } = await response.json();
        const sessionId = id;


        const stripe = await stripePromise;
        await stripe?.redirectToCheckout({ sessionId });
      } catch (error) {
        console.error('Error:', error);
        alert('Виникла помилка при створенні сесії оплати. Спробуйте ще раз.');
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-24 lg:px-6">
        <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
          <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Варіанти навчання
          </h2>
        </div>
        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          {plans.map((plan, index) => (
            <div
              key={index}
              className="flex flex-col justify-between max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-3xl shadow-lg dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white "
            >
              <div>
                <h3 className="mb-4 text-2xl font-semibold">{plan.name}</h3>
                <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">{plan.description}</p>
                <div className="flex flex-col  justify-center items-center my-8">
                  <div>
                    <span className="mr-2 text-2xl text-gray-500 line-through">{plan.oldPrice}</span>
                    <span className="text-5xl font-extrabold text-red-600">{plan.price}</span>
                  </div>
                  {/* <div className="text-sm">(ціна діє до кінця жовтня)</div> */}
                </div>
                <ul role="list" className="mb-8 space-y-4 text-left">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start space-x-3">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                {plan.detailed && (
                  <button
                    onClick={() => toggleDetails(index)}
                    className="text-blue-500 hover:underline"
                  >
                    {expandedPlan === index ? 'Сховати' : 'Детальніше'}
                  </button>
                )}
                {expandedPlan === index && plan.detailed && (
                  <p className="mt-4 text-sm text-left text-gray-500 dark:text-gray-400">
                    {plan.detailed}
                  </p>
                )}
              </div>
              <div className="flex flex-col justify-center items-center mt-6">
                {plan.type === 'premium' || plan.type === 'vip' ? (
                  <div data-tooltip-id={`plan-${index}`}>
                    <p
                      className="text-white cursor-default bg-yellow-500 transform transition hover:scale-105 duration-200 ease-in-out focus:ring-4 focus:ring-purple-200 font-medium rounded-2xl text-sm md:text-lg px-5 py-2.5 text-center dark:text-white dark:focus:ring-purple-900 mt-6"
                    >
                      Придбати курс
                    </p>
                    <Tooltip className='opaque'  id={`plan-${index}`} place="top" clickable>
                      <h2 className="text-white text-lg font-bold mb-2">Виберіть групу для {plan.name}</h2>
                      {plan.subtypes?.map((subtype) => (
                        <button
                          key={subtype.type}
                          onClick={() => {
                            handleCheckout(subtype.type);
                          }}
                          className={`block w-full px-2 py-1 text-left rounded-md mb-1 bg-yellow-500 hover:bg-yellow-600 transition text-white bg-yellow opacity-100`}
                        >
                          {subtype.name}
                        </button>
                      ))}
                    </Tooltip>
                  </div>
                ) : (
                  <button
                    onClick={() => handleCheckout(plan.type)}
                    className="text-white bg-yellow-500 transform transition hover:scale-105 duration-200 ease-in-out focus:ring-4 focus:ring-purple-200 font-medium rounded-2xl text-sm md:text-lg px-5 py-2.5 text-center dark:text-white dark:focus:ring-purple-900 mt-6"
                  >
                    Придбати Курс
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
