import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { addDoc, collection, query, where, onSnapshot, orderBy, limit, startAfter, serverTimestamp, getFirestore } from 'firebase/firestore';
import { app } from '../../firebase';

// Інтерфейс для типу коментаря
interface Comment {
    id: string;
    userId: string;
    username: string;
    content: string;
    timestamp: any;
}

const Comments = ({ lessonId }: { lessonId: string }) => {
    const db = getFirestore(app);
    const user = useSelector((state: any) => state.auth.user);
    const [comments, setComments] = useState<Comment[]>([]);
    const [newComment, setNewComment] = useState('');
    const [lastVisible, setLastVisible] = useState<any>(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true); // Новий стан для індикації завантаження
    const COMMENTS_LIMIT = 5; // Кількість коментарів, які завантажуються за раз


    // useEffect(() => {
    //     const q = query(collection(db, 'comments'), where('lessonId', '==', lessonId));
    //     const unsubscribe = onSnapshot(q, (snapshot) => {
    //         const fetchedComments = snapshot.docs.map(doc => ({
    //             id: doc.id,
    //             ...doc.data()
    //         })) as Comment[]; // Вказуємо, що це тип Comment[]
    //         setComments(fetchedComments);
    //     });

    //     return () => unsubscribe();
    // }, [lessonId, db]);


    useEffect(() => {
        const q = query(
            collection(db, 'comments'),
            where('lessonId', '==', lessonId),
            orderBy('timestamp', 'desc'),
            limit(COMMENTS_LIMIT)
        );


        const unsubscribe = onSnapshot(
            q,
            (snapshot) => {
                if (!snapshot.empty) {
                    const fetchedComments = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data()
                    })) as Comment[];

                    setComments(fetchedComments);
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                    setHasMore(snapshot.docs.length === COMMENTS_LIMIT);
                } else {
                    setComments([]); // Якщо немає коментарів, встановлюємо порожній масив
                    setHasMore(false);
                }
                setLoading(false); // Завершуємо завантаження
            },
            (error) => {
                console.error("Error fetching comments: ", error);
                setLoading(false);
            }
        );

        return () => unsubscribe();
    }, [lessonId, db]);



    const loadMoreComments = () => {
        if (lastVisible) {
            const nextQuery = query(
                collection(db, 'comments'),
                where('lessonId', '==', lessonId),
                orderBy('timestamp', 'desc'),
                startAfter(lastVisible),
                limit(COMMENTS_LIMIT)
            );

            onSnapshot(
                nextQuery,
                (snapshot) => {
                    if (!snapshot.empty) {
                        const fetchedComments = snapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data()
                        })) as Comment[];

                        setComments((prevComments) => [...prevComments, ...fetchedComments]);
                        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                        setHasMore(snapshot.docs.length === COMMENTS_LIMIT);
                    } else {
                        setHasMore(false);
                    }
                },
                (error) => {
                    console.error("Error loading more comments: ", error);
                }
            );
        }
    };

    const handleAddComment = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (newComment.trim() && user?.userId) {
            await addDoc(collection(db, 'comments'), {
                lessonId,
                userId: user.userId,
                username: user.email || 'Анонім',
                content: newComment,
                timestamp: serverTimestamp(),
            });
            setNewComment('');
        }
    };

    return (
        <div className="w-full mt-8 bg-gray-100 p-6 rounded-lg">
            <h3 className="text-2xl mb-4">Задавай питання сюди</h3>
            <form onSubmit={handleAddComment} className="flex flex-col mb-6">
                <textarea
                    className="p-2 mb-2 border rounded"
                    rows={3}
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Напиши коментар..."
                ></textarea>
                <button
                    type="submit"
                    className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600"
                    disabled={!user || !newComment.trim()}
                >
                    Відправити
                </button>
            </form>
            {loading ? (
                <p>Завантаження коментарів...</p>
            ) : (
                <div>
                    {comments.length > 0 ? (
                        comments.map((comment, index) => (
                            <div key={`${comment.id}-${index}`} className="border-b py-2">
                                <p className="font-semibold">{comment.username}</p>
                                <p>{comment.content}</p>
                                <span className="text-gray-500 text-sm">
                                    {new Date(comment.timestamp?.toDate()).toLocaleString('uk-UA')}
                                </span>
                            </div>
                        ))
                    ) : (
                        <p>Немає коментарів. Будь першим!</p>
                    )}
                </div>
            )}
            {hasMore && !loading && (
                <button
                    onClick={loadMoreComments}
                    className="mt-4 bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600"
                >
                    Завантажити більше коментарів
                </button>
            )}
        </div>
    );
};

export default Comments;
