import { all, call, put, takeLatest } from 'redux-saga/effects';
import { auth, firestore } from '../firebase';
import { addDoc, collection, doc, updateDoc, getDoc, DocumentReference, DocumentSnapshot, setDoc, DocumentData } from 'firebase/firestore';
import { createLesson, fetchLessonById, setLesson, lessonRequestStatus, setUploadProgress, addTaskToLesson, addTaskRequest, markSubtopicAsCompleted } from '../actions/lessonActions';
import { RequestStatus } from '../types/RequestStatus';
import { PayloadAction } from '@reduxjs/toolkit';
import { setUser } from '../actions/authActions';
import User, { UserRole } from '../types/User';

// Тип для уроку
interface Lesson {
    id?: string;
    name: string;
    videoUrl: string;
    tasks: string[];
}

interface Task {
    name: string;
    type: string;
    words?: string;
}

function* createLessonSaga(action: ReturnType<typeof createLesson>): Generator<any, void, DocumentReference> {
    yield put(lessonRequestStatus({ requestStatus: RequestStatus.Pending }));
    try {
        const { name, videoUrl, id } = action.payload;
        const newLesson = { name, videoUrl, id, tasks: [] };
        yield call(() => setDoc(doc(firestore, 'lessons', id), newLesson));
        yield put(setLesson({ id, name, videoUrl, tasks: [] }));
        yield put(lessonRequestStatus({ requestStatus: RequestStatus.Success }));

    } catch (error: any) {
        console.error('Error adding lesson:', error);
        yield put(lessonRequestStatus({ requestStatus: RequestStatus.Failure }));
    }
}

function* fetchLessonByIdSaga(action: PayloadAction<{ id: string }>): Generator<any, void, DocumentSnapshot> {
    yield put(lessonRequestStatus({ requestStatus: RequestStatus.Pending }));

    const { id } = action.payload;
    const lessonDoc: DocumentSnapshot<any> = yield call(getDoc, doc(firestore, 'lessons', id));

    try {
        if (lessonDoc.exists()) {
            const lessonData = lessonDoc.data();

            const lesson: any = {
                id: lessonDoc.id,
                videoUrl: lessonData.videoUrl,
                name: lessonData.name,
                tasks: lessonData.tasks,
            };

            yield put(setLesson(lesson));
            yield put(lessonRequestStatus({ requestStatus: RequestStatus.Success }));

        } else {
            console.error('Course not found in database');
            yield put(lessonRequestStatus({ requestStatus: RequestStatus.Success }));
        }
    } catch (error) {
        console.error('Error fetching lesson:', error);
        yield put(lessonRequestStatus({ requestStatus: RequestStatus.Failure }));
    }
}

function* addTaskSaga(action: PayloadAction<{ lessonId: string; task: Task }>): Generator<any, void, DocumentSnapshot<any>> {
    const { lessonId, task } = action.payload;
    yield put(lessonRequestStatus({ requestStatus: RequestStatus.Pending }));
    try {
        const lessonRef = doc(firestore, 'lessons', lessonId);
        const lessonSnapshot: DocumentSnapshot<any> = yield call(getDoc, lessonRef);

        if (!lessonSnapshot.exists()) {
            throw new Error("Lesson does not exist");
        }

        const lessonData = lessonSnapshot.data();
        const updatedTasks = [...lessonData.tasks, task];

        yield call(() => updateDoc(lessonRef, { tasks: updatedTasks }));
        yield put(addTaskToLesson(task));
        yield put(lessonRequestStatus({ requestStatus: RequestStatus.Success }));
    } catch (error) {
        console.error('Error adding task:', error);
        yield put(lessonRequestStatus({ requestStatus: RequestStatus.Failure }));
    }
}

function* markSubtopicAsCompletedSaga(action: ReturnType<typeof markSubtopicAsCompleted>) {
    const { courseId, topicId, subtopicId } = action.payload;
    const user = auth.currentUser;
    if (user) {
        try {
            const userDocRef = doc(firestore, `users/${user.uid}`);
            yield call(() => updateDoc(userDocRef, {
                [`courses/${courseId}/topics/${topicId}/subtopics/${subtopicId}`]: true,
                [`courses/${courseId}/topics/${topicId}/subtopics/${subtopicId}`]: 100,
            }));
            const userDoc: DocumentData = yield call(getDoc, userDocRef);
            const userData = userDoc.data() as User;
            

            yield put(setUser({
                user: {
                    userId: user.uid,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    metadata: {
                        createdAt: user.metadata.creationTime,
                        lastLoginAt: user.metadata.lastSignInTime
                    },
                    role: userData?.role || UserRole.FreeUser,
                    courses: userData.courses,
                    subscription: userData.subscription
                },
            }));

            console.log("Субтопік успішно оновлено на завершений.");
        } catch (error) {
            console.error("Помилка оновлення субтопіка: ", error);
        }
    }
}

function* lessonSaga() {
    yield takeLatest(createLesson.type, createLessonSaga);
    yield takeLatest(fetchLessonById.type, fetchLessonByIdSaga);
    yield takeLatest(addTaskRequest.type, addTaskSaga);
    yield takeLatest(markSubtopicAsCompleted.type, markSubtopicAsCompletedSaga);
}

export default lessonSaga;
