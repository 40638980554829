import React, { useState } from "react";
import { uploadFile } from "../../pages/Lesson/uploadFile";

interface UploadedFile {
  file: File;
  name: string;
  url: string | null;
}

interface AudioUploaderProps {
  onFilesUpload: (files: { name: string; url: string }[]) => void;
}

const AddMultipleAudioForTask: React.FC<AudioUploaderProps> = ({ onFilesUpload }) => {
  const [files, setFiles] = useState<UploadedFile[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    const newFiles = selectedFiles.map((file) => ({
      file,
      name: file.name.replace(/\.[^/.]+$/, ""), // видаляємо розширення за замовчуванням
      url: null
    }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleNameChange = (index: number, newName: string) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index].name = newName;
      return updatedFiles;
    });
  };

  const handleUpload = async () => {
    const uploadedFiles: { name: string; url: string }[] = [];
    for (const file of files) {
      if (file.url === null) {
        try {
          const downloadURL = await uploadFile(file.file, {
            onProgress: (progress) => console.log(`Upload is ${progress}% done`)
          });
          file.url = downloadURL;
          uploadedFiles.push({ name: file.name, url: downloadURL });
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    }
    setFiles((prevFiles) =>
      prevFiles.map((file) => ({
        ...file,
        url: file.url || null,
      }))
    );
    onFilesUpload(uploadedFiles); // передаємо інформацію про завантажені файли батьківському компоненту
  };

  return (
    <div>
      <label htmlFor="audioUpload">Upload Multiple Audio Files (.mp3, .wav, .m4a)</label>
      <input
        type="file"
        id="audioUpload"
        accept=".mp3,.wav,.m4a"
        onChange={handleFileChange}
        className="w-full border border-gray-300 p-2"
        multiple
      />
      {files.map((file, index) => (
        <div key={index} className="mt-4">
          <input
            type="text"
            value={file.name}
            onChange={(e) => handleNameChange(index, e.target.value)}
            placeholder="Enter file name"
            className="border border-gray-300 p-2 w-full"
          />
          <div className="mt-2">
            {file.url ? (
              <div className="p-2 rounded-md bg-green-300/50">
                {file.name} Uploaded ✔
              </div>
            ) : (
              <button
                onClick={handleUpload}
                className="p-2 rounded-md bg-white hover:bg-yellow-200/20 mt-2"
              >
                Upload {file.name}
              </button>
            )}
          </div>
        </div>
      ))}
      {files.length > 0 && (
        <button onClick={handleUpload} className="p-2 mt-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md">
          Upload All Files
        </button>
      )}
    </div>
  );
};

export default AddMultipleAudioForTask;
