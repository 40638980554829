import * as Yup from 'yup';

export const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .email('Некоректний формат електронної адреси')
    .required('Це поле є обов’язковим'),
  password: Yup.string()
    .required('Це поле є обов’язковим')
    .min(6, 'Пароль має містити принаймні 6 символів'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Паролі повинні співпадати')
    .required('Це поле є обов’язковим'),
});


export const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Некоректний формат електронної адреси')
    .required('Це поле є обов’язковим'),
  password: Yup.string()
    .required('Це поле є обов’язковим')
});


