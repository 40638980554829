import React, { useEffect, useRef } from 'react';
import styles from './AddFillTheBlanksTask.module.scss';

interface DisplayHtmlContentWithInputsProps {
  content: string;
  results: boolean[];
  userInputs: string[];
  isTaskCompleted: boolean;
  answers: string[]; // Масив правильних відповідей
}

const DisplayHtmlContentWithInputs: React.FC<DisplayHtmlContentWithInputsProps> = ({ content, results, userInputs, isTaskCompleted, answers }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const inputs = containerRef.current?.querySelectorAll<HTMLInputElement>('input[data-id]');
    inputs?.forEach((input, index) => {
      input.classList.add(styles.input);
      input.setAttribute('placeholder', `Введіть відповідь`);
      input.value = '';

      if (userInputs[index] !== undefined && userInputs[index] !== '') {
        input.value = userInputs[index]; // Встановлюємо збережене значення
      }

      // Визначаємо ширину інпуту на основі очікуваної відповіді
      if (answers[index]) {
        const answerLength = answers[index].length;
        const minWidth = 75; // Мінімальна ширина
        const maxWidth = 350; // Максимальна ширина
        const calculatedWidth = Math.min(Math.max(answerLength * 10, minWidth), maxWidth); // Вираховуємо ширину
        input.style.width = `${calculatedWidth}px`;
      }
    });
  }, [userInputs, answers]);

  useEffect(() => {
    const inputs = containerRef.current?.querySelectorAll<HTMLInputElement>('input[data-id]');
    if (isTaskCompleted) {
      inputs?.forEach((input, index) => {
        if (results[index] !== undefined) {
          if (results[index]) {
            input.classList.remove(styles.incorrect);
            input.classList.add(styles.correct);
            // Прибираємо блок з правильною відповіддю, якщо відповідь правильна
            const correctAnswerBlock = input.previousElementSibling as HTMLElement;
            if (correctAnswerBlock) {
              correctAnswerBlock.style.display = 'none';
            }
          } else {
            input.classList.remove(styles.correct);
            input.classList.add(styles.incorrect);

            // Додаємо правильну відповідь над інпутом
            let correctAnswerBlock = input.previousElementSibling as HTMLElement;
            if (!correctAnswerBlock || !correctAnswerBlock.classList.contains(styles.correctAnswer)) {
              correctAnswerBlock = document.createElement('div');
              correctAnswerBlock.className = styles.correctAnswer;
              correctAnswerBlock.textContent = `${answers[index]}`;
              input.parentNode?.insertBefore(correctAnswerBlock, input);
            } else {
              correctAnswerBlock.textContent = `${answers[index]}`;
              correctAnswerBlock.style.display = 'inline';
            }
          }
        }
      });
    }
  }, [results, userInputs, isTaskCompleted, answers]);

  return (
    <div className='mb-6 text' ref={containerRef} dangerouslySetInnerHTML={{ __html: content }} />
  );
};

export default DisplayHtmlContentWithInputs;
