import { Link } from 'react-router-dom';
import SocialLinks from '../../components/SocialLinks';

function ContactPage() {
  return (
    <div className="max-w-screen-xl px-8 mx-auto flex flex-col lg:flex-row items-start mt-10">
      {/* Ліва колонка */}
      <div className="flex flex-col w-full lg:w-6/12 justify-center lg:pt-24 items-start text-center lg:text-left mb-5 md:mb-0">
        <h1 className="my-4 text-3xl md:text-5xl font-bold leading-tight text-darken text-center lg:text-left">
          Привіт! Мене звати Марія
        </h1>
        <p className="leading-normal text-lg md:text-2xl mb-4">
          Я викладач та перекладач з польської мови.
          Навчалася в Варшавському університеті на факультеті міжнародних відносин і проживаю в Польщі від 2014 року.
        </p>
        <p className="leading-normal text-md md:text-lg mb-4">
          Працювала в кількох великих польських компаніях, тому володію живою та професійною польською мовою.
          Весь час вдосконалюю свої навички та готуюсь до екзамену присяжного перекладача.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Чому я хороший викладач:
        </h2>
        <ul className="list-disc pl-5 text-left space-y-2 mb-6 list-none">
          <li>🔥 Вивчила польську мову з нуля і знаю, як зрозуміло пояснити найскладніші теми.</li>
          <li>🔥 Зібрала найцінніші знання у своєму курсі, створивши захоплюючі уроки з сучасними завданнями.</li>
          <li>🔥 Використовую багато аудіоматеріалів записаних носіями мови, актуальні тексти, статті та відео.</li>
          <li>🔥 Навіть без участі в розмовних заняттях, після курсу ви заговорите польською!</li>
          <li>🔥 Мені подобається викладати польську мову і бачити, як учні досягають своїх цілей.</li>
          <li>🔥 Я створила платформу, де немає книжок, а тільки цікаві завдання у сучасній формі.</li>
        </ul>
        <p className="text-lg font-semibold mb-6">ЗАПРОШУЮ!</p>
        <div className="w-full flex items-center justify-center lg:justify-start space-x-5 mb-6">
          <a
            href="/"
            className="flex items-center gap-2 bg-yellow-500 text-white text-md md:text-lg font-bold rounded-full py-3 px-8 focus:outline-none transform transition hover:scale-105 duration-200 ease-in-out"
          >
            Перейти на головну сторінку
          </a>
        </div>
        <p className="text-md md:text-lg mb-4">
          Якщо у вас виникнуть будь-які запитання, пишіть мені в особисті повідомлення:
        </p>
        <div className="flex space-x-4 mb-6">
          <SocialLinks />
        </div>
        <p className="text-md md:text-lg mb-4">
          P.S. А ще, я люблю готувати смаколики, тому запрошую на мій польськомовний YouTube канал:
        </p>
          <a
            href="https://youtu.be/R3psSqlNap0?si=iXfXMs4_lT4Af1NJ"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 mb-6"
          >
            Мій YouTube канал
          </a>
      </div>
      <div className='lg:m-20 w-full lg:w-4/12 rounded-3xl overflow-hidden mb-10'>
        <img src="https://firebasestorage.googleapis.com/v0/b/test-course-88f8a.appspot.com/o/images%2Fslides%2FSesja_1%20(1)%20(2).jpg?alt=media&token=33f8cb43-7125-4b68-b63a-1dd833302116" alt="" />
      </div>
    </div>
  );
}

export default ContactPage;