import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';

interface Props {
  children: React.ReactElement | React.ReactElement[] | string
}

function MainLayout({ children }: Props) {
  return (
    <div className='min-h-screen h-full flex flex-col '>
      <Navbar />
      {children}
      <Footer />
    </div>
  );
}

export default MainLayout;
