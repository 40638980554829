import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Lesson, Task } from '../types/Lesson';
import { RequestStatus } from '../types/RequestStatus';

interface LessonState {
  lesson: Lesson | null;
  requestStatus: RequestStatus;
  uploadProgress: number;
}

const initialState: LessonState = {
  lesson: null,
  requestStatus: RequestStatus.Idle,
  uploadProgress: 0,
};

const lessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    setLesson(state, action: PayloadAction<Lesson>) {
      if (action.payload) {
        state.lesson = action.payload;
      }
    },
    lessonRequestStatus(state, action: PayloadAction<{ requestStatus: RequestStatus }>) {
      state.requestStatus = action.payload.requestStatus;
    },
    setUploadProgress(state, action: PayloadAction<number>) {
      state.uploadProgress = action.payload;
    },
    addTaskToLesson(state, action: PayloadAction<Task>) {
      if (state.lesson) {
        state.lesson.tasks.push(action.payload);
      }
    },
    addTaskRequest(state, action: PayloadAction<{ lessonId: string; task: Task }>) {
      // This is just a placeholder for the saga
    },
  },
});

export const { setLesson, lessonRequestStatus, setUploadProgress, addTaskToLesson, addTaskRequest } = lessonSlice.actions;

export default lessonSlice.reducer;
