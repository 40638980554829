// AddTask.tsx
import React, { useState } from 'react';

interface Question {
  question: string;
  options: string[];
  correctAnswer: string;
}

interface AddTaskProps {
  questions: Question[];
  onAddQuestion: (question: Question) => void;
  onDeleteQuestion: (index: number) => void;
  onClearQuestions: () => void;
}

const AddTask: React.FC<AddTaskProps> = ({ questions, onAddQuestion, onDeleteQuestion, onClearQuestions }) => {
  const [question, setQuestion] = useState<string>('');
  const [options, setOptions] = useState<string[]>(['', '', '', '']);
  const [correctAnswer, setCorrectAnswer] = useState<string>('');

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const addQuestionToList = () => {
    const newQuestion: Question = { question, options, correctAnswer };
    onAddQuestion(newQuestion);
    setQuestion('');
    setOptions(['', '', '', '']);
    setCorrectAnswer('');
  };

  const handleSubmit = async () => {
    try {
      onClearQuestions();
    } catch (e) {
      console.error("Error adding documents: ", e);
    }
  };

  return (
    <div>
      <h2>Add New Task</h2>
      <textarea
        placeholder="Enter the question"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />
      {options.map((option, index) => (
        <input
          key={index}
          type="text"
          placeholder={`Option ${index + 1}`}
          value={option}
          onChange={(e) => handleOptionChange(index, e.target.value)}
        />
      ))}
      <select
        value={correctAnswer}
        onChange={(e) => setCorrectAnswer(e.target.value)}
      >
        <option value="" disabled>Select the correct answer</option>
        {options.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
      <button onClick={addQuestionToList}>Add Question to List</button>
      <h3>Questions List</h3>
      <ul>
        {questions.map((q, index) => (
          <li key={index}>
            {q.question} - Correct Answer: {q.correctAnswer}
            <button onClick={() => onDeleteQuestion(index)}>Delete</button>
          </li>
        ))}
      </ul>
      <button onClick={handleSubmit}>Submit All Questions</button>
    </div>
  );
};

export default AddTask;
