import React from "react";

const Footer = () => {
  return (
    <footer  style={{ backgroundColor: "rgba(37, 38, 65, 1)" }}>
      <div className="max-w-lg mx-auto">
        <div className="flex py-12 justify-center text-white items-center px-20 sm:px-36">
          <div className="relative pr-5">
            <a
              href="/"
              className="text-lg relative z-50 font-bold tracking-widest text-gray-900 rounded-lg focus:outline-none focus:shadow-outline"
            >
              <img className='w-16 lg:w-20' src="/logo.png" alt="logo" />
            </a>
          </div>
          <span className="border-l text-center border-gray-500 text-sm pl-5 py-2 font-semibold">
          Mariia Polski online
          </span>
        </div>
        <div className="flex items-center text-gray-400 text-sm justify-center">
          <a href="#" className=" px-3">
            Privacy
          </a>
          <a href="#" className="border-l border-gray-400 pl-3">
            Terms & Conditions
          </a>
        </div>
        <div className="text-center text-white">
          <p className="my-3 text-gray-400 text-sm">
            &copy; 2024 Mariia Polski online.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
