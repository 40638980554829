import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import courseSaga from './courseSaga';
import lessonSaga from './lessonSaga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    courseSaga(),
    lessonSaga()
  ]);
}
