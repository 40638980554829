import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBKCWdktxaMkcxfJicL6QcbfQZO1GMFBPY",
  authDomain: "test-course-88f8a.firebaseapp.com",
  projectId: "test-course-88f8a",
  storageBucket: "test-course-88f8a.appspot.com",
  messagingSenderId: "719820190546",
  appId: "1:719820190546:web:4f081e1da92db506c2008b"
};

export const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();