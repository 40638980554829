import classNames from "classnames";
import styles from "./Button.module.scss";
import { Link } from "react-router-dom";

interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  text: string;
  className?: string;
  disabled?: boolean;
  isLink?: boolean;
  path?: string;
  type?: "button" | "submit" | "reset" | undefined;
  variant?: "primary" | "secondary"
}

function Button({
  onClick,
  text,
  className = "",
  disabled = false,
  isLink = false,
  path = "",
  type,
  variant = "primary"
}: ButtonProps) {
  const buttonClass = classNames(
    "cursor-pointer px-6 py-3 rounded-lg transition duration-200 ease-in-out text-[24px] border-0", {
    "text-white bg-yellow-500 hover:bg-white hover:text-gray-700": variant === "primary",
    "text-white bg-yellow-500 hover:bg-[#DB1A91] ": variant === "secondary",
    "disabled:bg-[rgba(29,29,29,0.07)]": disabled
  },
    className,
  );
  if (isLink) {
    return (
      <Link className={buttonClass} to={path}>
        {text}
      </Link>
    );
  } else {
    return (
      <button
        className={buttonClass}
        onClick={onClick}
        disabled={disabled}
        type={type}
      >
        {text}
      </button>
    );
  }
}

export default Button;