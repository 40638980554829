import React, { useState } from "react";
import { uploadFile } from "../../pages/Lesson/uploadFile";

interface FileUploaderProps {
  onFileUpload: (fileUrl: string) => void;
}

const AddFileForTask: React.FC<FileUploaderProps> = ({ onFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Перевірка на тип файлу (тільки зображення)
      if (!file.type.startsWith('image/')) {
        alert('Please upload a valid image file (jpg, png, etc.).');
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const downloadURL = await uploadFile(selectedFile, {
          onProgress: (progress) => console.log(`Upload is ${progress}% done`)
        });
        setFileUrl(downloadURL);
        setSelectedFile(null);
        onFileUpload(downloadURL); // Оповістіть батьківський компонент про новий URL
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <div>
      <label htmlFor="fileUpload">Upload Image</label>
      <input
        type="file"
        id="fileUpload"
        accept="image/*"
        onChange={handleFileChange}
        className="w-full border border-gray-300 p-2"
      />
      {selectedFile && (
        <button onClick={handleUpload} className="p-2 rounded-md bg-white hover:bg-yellow-200/20 mt-2">
          Upload Image
        </button>
      )}
      {fileUrl && (
        <div className="mt-2 p-2 rounded-md bg-green-300/50">
          Image Uploaded ✔
        </div>
      )}
    </div>
  );
};

export default AddFileForTask;
