import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

export const saveTranslationToFirebase = async (
  lessonId: string,
  taskId: number,
  word: string,
  translation: string
) => {
  try {
    // Звертаємось до документа уроку
    const docRef = doc(firestore, 'lessons', lessonId);
    const lessonDoc = await getDoc(docRef);

    if (!lessonDoc.exists()) {
      console.error('Lesson document not found');
      return;
    }

    // Отримуємо дані уроку
    const lessonData = lessonDoc.data();

    // Знаходимо завдання за ID
    const tasks = lessonData.tasks.map((task: any) => {
      if (task.id === taskId && task.wordsToSpeak) {
        // Оновлюємо wordsToSpeak у знайденому завданні
        task.wordsToSpeak = task.wordsToSpeak
          .split(',')
          .map((w: string) => {
            const [originalWord] = w.includes('=') ? w.split('=') : [w];
            if (originalWord.trim() === word) {
              return `${originalWord.trim()}=${translation.trim()}`;
            }
            return w.trim();
          })
          .join(',');
      }
      return task;
    });

    // Оновлюємо документ у Firebase
    await updateDoc(docRef, {
      tasks,
    });

    console.log(`Saved translation for ${word}: ${translation}`);
  } catch (error) {
    console.error('Error saving translation to Firebase:', error);
  }
};
