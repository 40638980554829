import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';

interface UploadVideoOptions {
  onProgress?: (progress: number) => void;
}

export const uploadVideo = (file: File, options: UploadVideoOptions = {}) => {
const storage = getStorage()

  return new Promise<string>((resolve, reject) => {
    const videoRef = ref(storage, 'videos/' + file.name);
    const uploadTask = uploadBytesResumable(videoRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (options.onProgress) {
          options.onProgress(progress);
        }
      },
      (error) => {
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
};