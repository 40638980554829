import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';

export const saveTranslationToFirebaseGroup = async (
  lessonId: string,
  taskId: number,
  word: string,
  translation: string
) => {
  try {
    // Звертаємось до документа уроку
    const docRef = doc(firestore, 'lessons', lessonId);
    const lessonDoc = await getDoc(docRef);

    if (!lessonDoc.exists()) {
      console.error('Lesson document not found');
      return;
    }

    // Отримуємо дані уроку
    const lessonData = lessonDoc.data();

    // Знаходимо завдання за ID
    const tasks = lessonData.tasks.map((task: any) => {
      if (task.id === taskId && task.wordsWithCategories) {
        console.log('Found task:', task);

        // Оновлюємо слова у знайденому завданні
        task.wordsWithCategories = task.wordsWithCategories.map((category: any) => {
          // Оновлюємо кожне слово в категорії
          category.words = category.words.map((w: any) => {
            if (w.word.trim() === word) {
              return {
                ...w,
                translation: translation.trim(),
              };
            }
            return w;
          });
          return category;
        });
      }
      return task;
    });

    // Оновлюємо документ у Firebase
    await updateDoc(docRef, {
      tasks,
    });

    console.log(`Saved translation for ${word}: ${translation}`);
  } catch (error) {
    console.error('Error saving translation to Firebase:', error);
  }
};
