import React from 'react';

const CourseTopicsList = () => {
  // Масив з темами курсу
  const topics = [
    { title: 'Тема 1 Алфавіт', subtopics: ['1.1 Букви', '1.2 Буквосполучення', '1.3 Складні моменти'] },
    { title: 'Тема 2 Перша зустріч', subtopics: ['2.1 Давайте познайомимося', '2.2 ІІІ дієвідміна дієслів', '2.3 Числа 1-20'] },
    { title: 'Тема 3 Персональні дані', subtopics: ['3.1 Йдемо до державної установи', '3.2 Відмінювання дієслова «бути»', '3.3 Числа 20-1000'] },
    { title: 'Тема 4 Закінчення прикметників та іменників у називному відмінку', subtopics: ['4.1 Нові слова', '4.2 Чоловічий, жіночий, середній рід (закінчення)', '4.3 Кольори'] },
    { title: 'Тема 5 Я розмовляю польською мовою', subtopics: ['5.1 ІІ дієвідміна дієслів', '5.2 Країни та мови', '5.3 Скільки тобі років?'] },
    { title: 'Тема 6 Що я люблю робити?', subtopics: ['6.1 І дієвідміна дієслів', '6.2 Хоббі і спорти', '6.3 Займенники з ким, з чим?'] },
    { title: 'Тема 7 Хто я?', subtopics: ['7.1 Професії', '7.2 Орудний (закінчення – ą)', '7.3 Орудний відмінок множини'] },
    { title: 'Тема 8 Що і з чим я їм?', subtopics: ['8.1 Знахідний (закінчення – ę)', '8.2 Що я їм і п’ю на сніданок?', '8.3 Що я їм і п’ю на обід?'] },
    { title: 'Тема 9 В ресторані', subtopics: ['9.1 Робимо замовлення в ресторані', '9.2 Займенники в давальному', '9.3 Замовляємо піцу з доставкою'] },
    { title: 'Тема 10 Купуємо фрукти та овочі', subtopics: ['10.1 Фрукти та овочі', '10.2 Форма і смак', '10.3 Множина'] },
    { title: 'Тема 11 Моя сім’я', subtopics: ['11.1 Моя сім’я – слова', '11.2 Присвійні займенники', '11.2 Займенники в західному'] },
    { title: 'Тема 12 Покупки в продуктовому магазині', subtopics: ['12.1 Родовий відмінок множини', '12.2 Продуктовий магазин - слова', '12.3 Що я не люблю (родовий відмінок однини)?'] },
    { title: 'Тема 13 Як туди дійти/доїхати?', subtopics: ['13.1 Транспортні засоби', '13.2 Дієслова руху', '13.3 Як туди дістатися?'] },
    { title: 'Тема 14 Мій план дня, котра година', subtopics: ['14.1 Мій план дня – нові дієслова', '14.2 Котра година', '14.3 Дні тижня'] },
    { title: 'Тема 15 Минулий час', subtopics: ['15.1 Минулий час – основи', '15.2 Минулий час - винятки'] },
    { title: 'Тема 16 Що буде?', subtopics: ['16.1 Майбутній час', '16.2 Пори року, назви місяців', '16.3 Прогноз погоди'] },
    { title: 'Тема 17 Ідемо до косметолога та перукаря', subtopics: ['17.1 Як я виглядаю', '17.2 Ідемо до перукаря', '17.3 Ідемо до косметолога'] },
    { title: 'Тема 18 Орендуємо квартиру', subtopics: ['18.1 Меблі і опис квартири', '18.2 Місцевий відмінок', '18.3 Бронюємо номер в готелі', '18.4 Розмовляємо з власником квартири'] },
    { title: 'Тема 19 Ідемо до лікаря', subtopics: ['19.1 Тіло людини', '19.2 Хвороби і симптоми', '19.3 В лікаря'] },
    { title: 'Тема 20 Подорожуємо по Польщі', subtopics: ['20.1 Напрямки світу', '20.2 Цікаві місця', '20.3 Купуємо квиток на потяг'] },
  ];

  return (
    <div className="rounded-lg p-6 mx-auto">
      <h2 className="text-2xl font-bold mb-4 text-center">Теми курсу:</h2>
      <ul className="flex flex-wrap justify-center gap-4">
        {topics.map((topic, index) => (
          <li key={index} className="bg-white text-lg rounded-3xl shadow-xl p-5 w-full lg:w-1/4">
            <div className="flex items-center mb-2">
              <svg
                className="w-6 h-6 text-blue-500 flex-shrink-0 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zm5 12.75h-4v4a.75.75 0 01-1.5 0v-4h-4a.75.75 0 010-1.5h4v-4a.75.75 0 011.5 0v4h4a.75.75 0 010 1.5z"
                />
              </svg>
              <h3 className="font-semibold">{topic.title}</h3>
            </div>
            <ul className="ml-9">
              {topic.subtopics.map((subtopic, subIndex) => (
                <li key={subIndex} className="text-gray-700">
                  {subtopic}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CourseTopicsList;
