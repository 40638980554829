import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { addTaskRequest } from "../../actions/lessonActions";
import AddWordsComponent from "../../components/AddWordList";
import AddFileForTask from "../../components/AddFileForTask";
import AddWordsWithCateggory from "../../components/AddWordsWithCateggory";
import EditorComponent from "../../components/AddFillTheBlanksTask";
import AddChooseCorrectAnswer from "../../components/AddChooseCorrectAnswer";
import AddImageMatchTask from "../../components/AddImageMatchTask";
import AddMultipleAudioForTask from "../../components/AddMultipleAudioForTask";

export enum TaskType {
    SpeakWords = 'speakWords',
    WriteWhatYouHear = 'writeWhatYouHear',
    WordGroups = 'wordGroups',
    DownloadFile = 'downloadFile',
    FillTheBlanks = 'fillTheBlanks',
    ChooseCorrectAnswer = 'chooseCorrectAnswer',
    ChooseCorrectImage = 'chooseCorrectImage',
    ListenAudio = 'listenAudio' 
}

interface WordGroup {
    name: string;
    words: string[];
}

interface Word {
    word: string;
    audioUrl: string;
}

interface Category {
    category: string;
    audioUrl: string;
    words: Word[];
}

interface Question {
    question: string;
    options: string[];
    correctAnswer: string;
}

function TaskCreator({ lessonId }: { lessonId?: string }) {
    const [taskName, setTaskName] = useState<string>("");
    const [taskType, setTaskType] = useState<TaskType>(TaskType.WriteWhatYouHear);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState<string>("");
    const [wordsForReading, setWordsForReading] = useState("");
    const [wordList, setWordList] = useState<{ word: string; audioUrl: string | null; }[]>([]);
    const [wordsWithCategories, setWordsWithCategories] = useState<Category[]>([]);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [questionsWithPicture, setWuestionsWithPicture] = useState<any>();
    const dispatch = useDispatch();
    const [textWithBlankvalue, setTextWithBlankvalue] = useState<any>({});
    const [audioFiles, setAudioFiles] = useState<{ name: string; url: string }[]>([]);

    const handleSave = (content: any) => {

        setTextWithBlankvalue(content);
    };




    const handleAddWord = (newWord: { word: string; audioUrl: string | null }) => {
        setWordList([...wordList, newWord]);
    };

    const addCategory = (category: string, audioUrl: string, words: Word[]) => {
        setWordsWithCategories([...wordsWithCategories, { category, audioUrl, words }]);
    };

    const handleAddQuestion = (newQuestion: Question) => {
        setQuestions([...questions, newQuestion]);
    };

    const handleDeleteQuestion = (index: number) => {
        const newQuestions = [...questions];
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
    };

    const handleClearQuestions = () => {
        setQuestions([]);
    };




    const handleAddImageQuestion = (newQuestion: Question) => {
        setWuestionsWithPicture(newQuestion);
    };

    const addTask = () => {
        if (lessonId) {
            const task = {
                id: Date.now(),
                name: taskName,
                type: taskType,
                ...(taskType === TaskType.SpeakWords && { wordsToSpeak: wordsForReading }),
                ...(taskType === TaskType.WriteWhatYouHear && { words: wordList }),
                ...(taskType === TaskType.WordGroups && { wordsWithCategories }),
                ...(taskType === TaskType.DownloadFile && { fileUrl }),
                ...(taskType === TaskType.FillTheBlanks && { taskWithBlanks: { text: textWithBlankvalue.content, answers: textWithBlankvalue.values } }),
                ...(taskType === TaskType.ChooseCorrectAnswer && { chooseCorrectAnswer: questions }),
                ...(taskType === TaskType.ChooseCorrectImage && { chooseCorrectImage: questionsWithPicture }),
                ...(taskType === TaskType.ListenAudio && { audioFiles }),
            };

            dispatch(addTaskRequest({ lessonId, task }));
            setIsModalOpen(false);
        }
    };

    return (
        <div>
            <button className="bg-white p-2 rounded-md hover:bg-yellow-100/20" onClick={() => setIsModalOpen(true)}>Create New Task</button>
            <Modal
                className="z-50 overflow-auto max-w-screen-md bg-white bg-main-gradient absolute p-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-2xl max-h-[600px] flex flex-col"
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
            >
                <div className="flex justify-between mb-10">
                    <h2 className="text-xl">Create a New Task</h2>
                    <button className="text-red-400" onClick={() => setIsModalOpen(false)}>Close</button>
                </div>
                <div className="flex flex-col gap-3 pb-6">
                    <label>Назва Завдання</label>
                    <input className="p-2 rounded-xl border-none outline-none" value={taskName} onChange={(e) => setTaskName(e.currentTarget.value)} type="text" />
                    <label>Тип</label>
                    <select className="p-2 rounded-xl border-none outline-none" name="type" value={taskType} onChange={(e) => setTaskType(e.currentTarget.value as TaskType)}>
                        <option value="writeWhatYouHear">Напиши слова на слух</option>
                        <option value="speakWords">Послухай як читається і читай вголос разом з лектором.</option>
                        <option value="wordGroups">Групи слів</option>
                        <option value="downloadFile">Завантажити файл</option>
                        <option value="fillTheBlanks">Заповнити пропуски</option>
                        <option value="chooseCorrectAnswer">Вибери правильну відповідь</option>
                        <option value="chooseCorrectImage">Поєднай картинку</option>
                        <option value="listenAudio">Слухати аудіо</option>
                    </select>
                </div>

                {taskType === TaskType.WriteWhatYouHear && (
                    <div>
                        <AddWordsComponent onAddWord={handleAddWord} />
                        <ul>
                            {wordList.map((item: { word: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; audioUrl: string | undefined | null; }, index: React.Key | null | undefined) => (
                                <li key={index}>
                                    {item.word} {item.audioUrl && <audio controls src={item.audioUrl}></audio>}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {taskType === TaskType.SpeakWords && (
                    <div>
                        <label htmlFor="words">Слова</label>
                        <textarea
                            value={wordsForReading}
                            onChange={(e) => setWordsForReading(e.currentTarget.value)}
                            className="w-full"
                            name="Words"
                            id="words"
                        ></textarea>
                    </div>
                )}

                {taskType === TaskType.WordGroups && (
                    <div>
                        <h1>Word Categories</h1>
                        <AddWordsWithCateggory addCategory={addCategory} />
                        <div>
                            {wordsWithCategories.map((category, index) => (
                                <div key={index}>
                                    <h2>{category.category}</h2>
                                    <audio controls src={category.audioUrl}>Your browser does not support the audio element.</audio>
                                    <ul>
                                        {category.words.map((wordObj, wordIndex) => (
                                            <li key={wordIndex}>
                                                {wordObj.word}
                                                <audio controls src={wordObj.audioUrl}>Your browser does not support the audio element.</audio>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {taskType === TaskType.DownloadFile && (
                    <AddFileForTask onFileUpload={setFileUrl} />
                )}

                {taskType === TaskType.FillTheBlanks && (
                    <div>
                        <EditorComponent onSave={handleSave} />
                    </div>
                )}

                {taskType === TaskType.ChooseCorrectAnswer && (
                    <div>
                        <AddChooseCorrectAnswer
                            questions={questions}
                            onAddQuestion={handleAddQuestion}
                            onDeleteQuestion={handleDeleteQuestion}
                            onClearQuestions={handleClearQuestions}
                        />
                    </div>
                )}

                {taskType === TaskType.ChooseCorrectImage && (
                    <AddImageMatchTask onAddQuestion={handleAddImageQuestion} />
                )}

                {taskType === TaskType.ListenAudio && (
                    <AddMultipleAudioForTask onFilesUpload={setAudioFiles} />
                )}





                <button onClick={addTask}>Створити</button>
            </Modal>
        </div>
    );
}

export default TaskCreator;
