import { call, put, take, takeLatest } from 'redux-saga/effects';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, UserCredential, signInWithPopup, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';
import { doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';
import {
    loginRequest,
    signupRequest,
    logoutRequest,
    logoutAction,
    loginRequestStatus,
    continueWithGoogleRequest,
    registrationRequestStatus,
    init,
    setUser,
    passwordResetRequest,
} from '../actions/authActions';
import { RequestStatus } from '../types/RequestStatus';
import { auth, firestore, googleProvider } from '../firebase';
import User, { UserRole } from '../types/User';
import { EventChannel, eventChannel, SagaIterator } from 'redux-saga';
import { toast } from 'react-toastify';

interface AuthEvent {
    user: User | null;
}

function createAuthChannel() {
    return eventChannel(emit => {
        const unsubscribe = onAuthStateChanged(auth, user => {


            if (user) {
                emit({ user });
            } else {
                emit({ user: null });
            }
        });
        return unsubscribe;
    });
}

function* monitorAuthState(): SagaIterator {
    const channel: EventChannel<AuthEvent> = yield call(createAuthChannel);

    yield put(loginRequestStatus({ requestStatus: RequestStatus.Pending }));

    while (true) {
        const { user }: UserCredential = yield take(channel);
        if (user) {
            const userDoc: DocumentSnapshot = yield call(getDoc, doc(firestore, `users/${user.uid}`));
            const userData = userDoc.data() as User;

            // Перевіряємо, чи є userData та чи містить воно властивість courses
            yield put(setUser({
                user: {
                    userId: user.uid,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    metadata: {
                        createdAt: user.metadata.creationTime,
                        lastLoginAt: user.metadata.lastSignInTime
                    },
                    role: userData?.role ?? UserRole.FreeUser,
                    courses: userData?.courses,
                    subscription: userData?.subscription ?? null
                },
            }));
            yield put(loginRequestStatus({ requestStatus: RequestStatus.Success }));
        } else {
            yield put(setUser({
                user: null,
            }));
            yield put(loginRequestStatus({ requestStatus: RequestStatus.Success }));
        }
    }
}

function* handleLogin(action: ReturnType<typeof loginRequest>) {
    yield put(loginRequestStatus({ requestStatus: RequestStatus.Pending }));
    const { email, password } = action.payload;
    try {
        const { user }: UserCredential = yield call(signInWithEmailAndPassword, auth, email, password);
        yield call(getDoc, doc(firestore, `users/${user.uid}`));

        yield call(setDoc as any, doc(firestore, `users/${user.uid}`), {
            metadata: {
                lastLoginAt: user.metadata.lastSignInTime
            },
        }, { merge: true });



        yield put(loginRequestStatus({ requestStatus: RequestStatus.Success }));
        toast.success('Login successful!'); // Успішне повідомлення

    } catch (error: any) {

        if (error.code === 'auth/invalid-credential') {
            toast.error('Введено неправильний email або пароль. Будь ласка, спробуйте ще раз.');
        } else if (error.code === 'auth/wrong-password') {
            toast.error('Введено неправильний email або пароль. Будь ласка, спробуйте ще раз.');
        } else if (error.code === 'auth/too-many-requests') {
            toast.error('Too many attempts. Please wait and try again later.');
        } else {
            toast.error('Something went wrong. Please try again.');
        }

        yield put(loginRequestStatus({ requestStatus: RequestStatus.Failure }));
    }
}

function* handleContinueWithGoogle() {
    yield put(loginRequestStatus({ requestStatus: RequestStatus.Pending }));

    try {
        const { user }: UserCredential = yield call(() => signInWithPopup(auth, googleProvider));
        const userRef = doc(firestore, `users/${user.uid}`);
        
        let userData: User | null = null;
        
        // Отримуємо документ користувача
        const userDoc: DocumentSnapshot = yield call(getDoc, userRef);

        if (userDoc.exists()) {
            // Якщо документ існує, беремо його дані
            userData = userDoc.data() as User;
        }

        // Записуємо або оновлюємо дані користувача
        yield call(setDoc as any, userRef, {
            email: user.email,
            emailVerified: user.emailVerified,
            metadata: {
                createdAt: user.metadata.creationTime,
                lastLoginAt: user.metadata.lastSignInTime
            },
            role: userData?.role ?? UserRole.FreeUser,
            courses: userData?.courses ?? [],
            subscription: userData?.subscription ?? null
        }, { merge: true }); // merge:true дозволяє додати дані, не видаляючи попередніх полів

        yield put(loginRequestStatus({ requestStatus: RequestStatus.Success }));
    } catch (error: unknown) {
        yield put(loginRequestStatus({ requestStatus: RequestStatus.Failure }));
    }
}


function* handleRegistration(action: ReturnType<typeof signupRequest>) {
    yield put(registrationRequestStatus({ requestStatus: RequestStatus.Pending }));

    const { email, password } = action.payload;

    try {
        const { user } = yield call(createUserWithEmailAndPassword, auth, email, password);

        yield call(setDoc as any, doc(firestore, `users/${user.uid}`), {
            email: user.email,
            emailVerified: user.emailVerified,
            metadata: {
                createdAt: user.metadata.creationTime,
                lastLoginAt: user.metadata.lastSignInTime
            },
            role: UserRole.FreeUser,
        });

        yield put(registrationRequestStatus({ requestStatus: RequestStatus.Success }));
        toast.success('Registration successful!'); // Успішне повідомлення

    } catch (error: any) {
        let errorMessage = 'Registration failed. Please try again.';
        if (error.code === 'auth/email-already-in-use') {
            errorMessage = 'Такий email вже використовується';
        } else if (error.code === 'auth/weak-password') {
            errorMessage = 'Password is too weak. Please choose a stronger one.';
        } else if (error.code === 'auth/invalid-email') {
            errorMessage = 'The email address is not valid.';
        }

        // Відображаємо повідомлення про помилку
        toast.error(errorMessage);

        yield put(registrationRequestStatus({ requestStatus: RequestStatus.Failure }));
    }
}


function* handleLogout() {
    const auth = getAuth();
    yield put(logoutAction({ status: RequestStatus.Pending }));

    try {
        yield call([auth, auth.signOut]);
        yield put(setUser({
            user: null,
        }));
        yield put(logoutAction({ status: RequestStatus.Success }));
    } catch (error) {
        yield put(logoutAction({ status: RequestStatus.Failure, payload: { error: (error as Error).message } }));
    }
}

function* handlePasswordReset(action: ReturnType<typeof passwordResetRequest>) {
    const auth = getAuth();
    const { email } = action.payload;

    try {
        yield call(sendPasswordResetEmail, auth, email);
        toast.success('Посилання для скидання пароля було надіслано на вашу електронну пошту.');
    } catch (error: any) {
        let errorMessage = 'Щось пішло не так. Будь ласка, спробуйте знову.';
        if (error.code === 'auth/user-not-found') {
            errorMessage = 'Користувача з таким імейлом не знайдено.';
        } else if (error.code === 'auth/invalid-email') {
            errorMessage = 'Невірний формат імейлу.';
        }
        toast.error(errorMessage);
    }
}

function* authSaga() {
    yield takeLatest(init.type, monitorAuthState);
    yield takeLatest(loginRequest.type, handleLogin);
    yield takeLatest(continueWithGoogleRequest.type, handleContinueWithGoogle);
    yield takeLatest(signupRequest.type, handleRegistration);
    yield takeLatest(logoutRequest.type, handleLogout);
    yield takeLatest(passwordResetRequest.type, handlePasswordReset);
}

export default authSaga;
