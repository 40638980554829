import { createAction } from '@reduxjs/toolkit';
import { RequestStatus } from '../types/RequestStatus';
import { Task } from '../types/Lesson';

export const lessonRequestStatus = createAction<{ requestStatus: RequestStatus }>('lesson/lessonRequestStatus');
export const fetchLessonById = createAction<{ id: string }>('lesson/fetchById');
export const setLesson = createAction<any>('lesson/setLesson');
export const createLesson = createAction<{ name: string, videoUrl: string, id: string }>('lesson/createLesson');
export const setUploadProgress = createAction<any>('lesson/setUploadProgress');
export const addTaskRequest = createAction<{ lessonId: string; task: any }>('lesson/addTaskRequest');
export const addTaskToLesson = createAction<any>('lesson/addTaskToLesson');
export const markSubtopicAsCompleted = createAction<{courseId: string; topicId: string; subtopicId: string;}>('lesson/markSubtopicAsCompleted');
