import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import CodeBlock from '@tiptap/extension-code-block'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Heading from '@tiptap/extension-heading'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import InputNode from '../../extensions/InputNode'

import styles from './AddFillTheBlanksTask.module.scss'


const MenuBar: React.FC<{ editor: ReturnType<typeof useEditor>; indexCount: number }> = ({ editor, indexCount }) => {

  if (!editor) {
    return null;
  }

  const handleInsertInput = () => {
    editor.chain().focus().insertContent({
      type: 'inputNode',
      attrs: {
        value: indexCount + 1,
        dataId: indexCount ,
      },
    }).run();
  };

  return (
    <div>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
      >
        Bold
      </button>
      {/* Інші кнопки */}
      <button
        onClick={handleInsertInput}
      >
        Insert Input
      </button>
    </div>
  );
}

interface MyEditorProps {
  onSave: ({ content, values }: { content: string, values: string[] }) => void;
}

const MyEditor: React.FC<MyEditorProps> = ({ onSave }) => {
  const [content, setContent] = useState<string>('');
  const [indexCount, setIndexCount] = useState<number>(0);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      CodeBlock,
      Bold,
      Italic,
      Underline,
      Heading,
      BulletList,
      OrderedList,
      ListItem,
      InputNode,
    ],
    content,
    onUpdate: ({ editor }) => {
      setContent(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor) {
      const inputCount = (editor.getHTML().match(/<input[^>]*>/g) || []).length;
      setIndexCount(inputCount);
      console.log(`Number of inputs in content: ${inputCount}`);
    }
  }, [content]);

  const handleSave = useCallback(() => {
    const values = inputRefs.current.map(input => input.value);

    const parser = new DOMParser();
    const serializer = new XMLSerializer();
    const doc = parser.parseFromString(content, 'text/html');

    const inputs = doc.querySelectorAll('input');
    inputs.forEach(input => {
      input.setAttribute('value', '');
    });

    const updatedContent = serializer.serializeToString(doc);
    setContent(updatedContent);
    console.log('Updated content:', updatedContent);

    onSave( {content: updatedContent, values} );
  }, [content, onSave]);

  const inputFields = Array.from({ length: indexCount }, (_, i) => (
    <input
      key={i}
      type="text"
      placeholder={`Input ${i + 1}`}
      ref={el => inputRefs.current[i] = el as HTMLInputElement}
    />
  ));

  return (
    <div>
      <MenuBar indexCount={indexCount} editor={editor} />
      <EditorContent editor={editor} className={styles.editorComponent} />
      <div className="flex flex-col gap-1">
        {inputFields}
      </div>
      <button onClick={handleSave}>Save</button>
    </div>
  );
}

export default MyEditor;