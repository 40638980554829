import React, { useEffect, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { saveAudioTaskDescription } from '../../utils/saveAudioTaskDescription';
import { UserRole } from '../../types/User';

interface ListenAudioTaskProps {
  task: any;
  index: number;
  onComplete: () => void;
  subtopicId: any;
  user: any
}

const ListenAudioTask: React.FC<ListenAudioTaskProps> = ({ task, onComplete, subtopicId, user }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localTask, setLocalTask] = useState(task);

  useEffect(() => {
    if (task) {
      setLocalTask(task); // Синхронізуємо локальний стан з новим `task`, якщо він змінюється
      setTimeout(() => {
        onComplete();
      }, 100);
    }
  }, [task]);

  // Editor instance for the task description
  const editor = useEditor({
    extensions: [StarterKit],
    content: localTask.description || '',
  });

  // Handle save task description
  const handleSave = async () => {
    if (editor) {
      const htmlContent = editor.getHTML();
      await saveAudioTaskDescription(localTask.id, subtopicId, htmlContent);
      setIsEditing(false);

      // Оновлюємо локальний стан, щоб миттєво відобразити новий опис
      setLocalTask((prevTask: any) => ({
        ...prevTask,
        description: htmlContent,
      }));
    }
  };

  return (
    <div className='flex flex-wrap gap-6'>
      {localTask.audioFiles.map((audio: any, idx: number) => (
        <div key={idx}>
          <h4 className='mb-2'>{audio.name}</h4>
          <audio className='border rounded-3xl' src={audio.url} controls />
        </div>
      ))}

      <div className='w-full mt-4'>
        {!isEditing && localTask.description && (
          <div
            className='mt-2'
            dangerouslySetInnerHTML={{ __html: localTask.description }}
          />
        )}
        {isEditing ? (
          <div className='w-full mt-4'>
            <EditorContent editor={editor} className='border p-2 rounded' />
            <button
              className='mt-2 bg-blue-500 text-white p-2 rounded'
              onClick={handleSave}
            >
              Save Description
            </button>
            <button
              className='mt-2 ml-2 bg-gray-300 text-black p-2 rounded'
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>
          </div>
        ) : (
          user.role === UserRole.Admin ? < button
            className='mt-2 text-blue-500 underline'
            onClick={() => setIsEditing(true)}
          >
            { user.role === UserRole.Admin ? (localTask.description ? 'Edit Description' : 'Add Description') : <></>}
          </button>
            : <></>
        )}
      </div>
    </div >
  );
};

export default ListenAudioTask;
