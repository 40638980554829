

export enum UserSubscriptionType {
    standard = 'СТАНДАРТ КУРС',
    premium1 = 'ПРЕМІУМ КУРС: Група 1 неділя 10:00',
    premium2 = 'ПРЕМІУМ КУРС: Група 2 понеділок 18:20',
    premium3 = 'ПРЕМІУМ КУРС: Група 3 вівторок 10:00',
    vip1 = 'VIP КУРС: Група 1: середа 18:20 і неділя 11:20',
    vip2 = 'VIP КУРС: Група 2 четвер 10:00 і неділя 12:40'
}

export function getCourseDetails(subscription: string): string {
    return UserSubscriptionType[subscription as keyof typeof UserSubscriptionType] || 'Курс не знайдено';
}   