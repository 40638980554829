import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { passwordResetRequest } from '../../actions/authActions';
import Button from '../Button';


function PasswordResetForm() {
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();

    const [isEmailSended, setIsEmailSended] = useState<boolean>(false)

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setIsEmailSended(true)
        dispatch(passwordResetRequest({ email }));
    };

    return (
        <form className='flex flex-col gap-2' onSubmit={handleSubmit}>
            {isEmailSended
                ? <h3>Будь ласка перевірте ваш email</h3>
                : <>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="shadow appearance-none border-gray-100 bg-white/85 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <Button
                        className='text-base w-full mb-6'
                        text="Відновити пароль"
                        variant='secondary'
                        type='submit'
                    />
                </>
            }
        </form>
    );
}

export default PasswordResetForm;