import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase";

export const saveAudioTaskDescription = async (taskId: number, lessonId: string, description:any) => {
    // Завантажуємо урок з Firestore
    const docRef = doc(firestore, 'lessons', lessonId);
    const lessonDoc = await getDoc(docRef);
  
    if (lessonDoc.exists()) {
      const lessonData = lessonDoc.data();
      const updatedTasks = lessonData.tasks.map((t: any) => {
        // Знаходимо таск за ID і оновлюємо його опис
        if (t.id === taskId) {
          return { ...t, description };
        }
        return t;
      });
  
      // Оновлюємо документ з новим масивом tasks
      await updateDoc(docRef, {
        tasks: updatedTasks,
      });
  
      console.log('Task description updated successfully');
    } else {
      console.error('Lesson not found');
    }
  };