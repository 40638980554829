import { combineReducers } from '@reduxjs/toolkit';
import authSlice from '../slices/authSlice';
import coursesSlice from '../slices/coursesSlice';
import lessonSlice from '../slices/lessonSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  course: coursesSlice,
  lesson: lessonSlice
});


export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;