// AddImageMatchTask.tsx
import React, { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

interface ImageMatchTask {
  images: { url: string; word: string }[];
  options: string[];
}

interface AddImageMatchTaskProps {
  onAddQuestion: (question: any) => any;
}

const AddImageMatchTask: React.FC<AddImageMatchTaskProps> = ({ onAddQuestion }) => {
  const [images, setImages] = useState<{ url: string; word: string }[]>([]);
  const [newImageFile, setNewImageFile] = useState<File | null>(null);
  const [newWord, setNewWord] = useState('');
  const [uploading, setUploading] = useState(false);

  const handleAddImage = async () => {
    if (!newImageFile) return;

    setUploading(true);

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `images/${newImageFile.name}`);

      await uploadBytes(storageRef, newImageFile);
      const url = await getDownloadURL(storageRef);

      const updatedImages = [...images, { url, word: newWord }];
      const updatedOptions = [...updatedImages.map(img => img.word)];

     
      onAddQuestion({
        images: updatedImages,
        options: updatedOptions,
      });

      
      setImages(updatedImages);
      setNewImageFile(null);
      setNewWord('');
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <h3>Add Image Match Task</h3>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => setNewImageFile(e.target.files?.[0] || null)}
      />
      <input
        type="text"
        placeholder="Associated Word"
        value={newWord}
        onChange={(e) => setNewWord(e.target.value)}
      />
      <button onClick={handleAddImage} disabled={uploading}>
        {uploading ? 'Uploading...' : 'Add Image and Word'}
      </button>

      <ul>
        {images.map((img, index) => (
          <li key={index}>
            <img src={img.url} alt={img.word} style={{ width: '50px', height: '50px' }} />
            {img.word}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddImageMatchTask;
