import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { RequestStatus } from '../../types/RequestStatus';
import LessonUpdater from './LessonUpdater';
import { fetchLessonById } from '../../actions/lessonActions';
import { UserRole } from '../../types/User';
import TaskCreator, { TaskType } from './TaskCreator';
import TaskRenderer from './TaskRenderer';
import SubtopicNavigation from '../../components/SubtopicNavigation';
import Comments from '../../components/Comments';

const Lesson: React.FC = () => {
  const { lessonId = '', courseId = '' } = useParams<{ lessonId: string, courseId: string }>();
  const dispatch = useDispatch();
  const { lesson, requestStatus } = useSelector((state: any) => state.lesson);
  const user = useSelector((state: any) => state.auth.user);

  const [currentTopic, setCurrentTopic] = useState<any>()

  function findTopicByLessonId(courses: any, lessonId: string) {
    if (courses) {
      for (const courseId in courses) {
        const course = courses[courseId];

        for (const topic of course.topics) {
          for (const subtopic of topic.subtopics) {
            if (subtopic.id === lessonId) {
              setCurrentTopic(topic);
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (user) {
      findTopicByLessonId(user?.courses, lessonId)
    }

  }, [user, lessonId]);


  useEffect(() => {
    dispatch(fetchLessonById({ id: lessonId || '' }))
  }, [lessonId])

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if ((requestStatus === RequestStatus.Pending || requestStatus === RequestStatus.Idle) && !lesson) {
    return <div><a className="inline-block align-baseline font-bold text-sm text-blue-500/80 hover:text-blue-800"
      href="/">Назад на головну сторінку</a></div>;
  }

  if (!user || !lesson) {
    return user && user.role === UserRole.Admin && <LessonUpdater id={lessonId || ''} />
  }


  return (
    <main className='flex flex-col items-center w-full relative'
    // style={{
    //   backgroundImage: "url('/LessonBg.jpg')",
    //   backgroundRepeat: 'no-repeat',
    //   backgroundPosition: '100px top'
    // }}
    >
      <div className='bg-main-gradient bg-contain bg-center w-full flex justify-center z-10'>
        <div className=' w-full container px-6'>
          {currentTopic && <SubtopicNavigation currentSubtopic={lesson} currentTopic={currentTopic} courseId={courseId} />}
          <div className="flex flex-col w-full items-center gap-6 lg:gap-12 mt-6 mb-12 ">
            <h1 className='text-3xl lg:text-4xl mb-6 text-center'>
              {lesson.name}
            </h1>
            <div className='flex flex-col-reverse lg:flex-row justify-between w-full gap-6 lg:gap-24'>
              <div className='w-full lg:w-1/4'>
                <h4 className='text-center text-3xl mb-6 font-medium'>
                  Список Завдань
                </h4>
                <div className='flex flex-col gap-4'>
                  {lesson && lesson.tasks.map((i: any, index: number) =>
                    <a className=' hover:bg-slate-200 rounded-xl p-1' href={`#${i.id}`} key={i.id}><span className='text-base lg:text-lg font-medium mr-1'>{index + 1}.</span><span className='text-lg'>{i.name}</span></a>)}
                </div>
              </div>
              <div className='flex-col  justify-between items-start gap-8 w-full lg:w-3/4'>
                <video key={lesson.videoUrl} className='w-full rounded-3xl' controls controlsList="nodownload">
                  <source src={lesson.videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

              </div>
            </div>
          </div>
          {user && user.role === UserRole.Admin && <TaskCreator lessonId={lessonId} />}
          <h1 className='text-2xl lg:text-4xl mb-6 text-center lg:text-left'>
            Завдання
          </h1>
          <div className='w-full'>
            {currentTopic && lesson && <TaskRenderer user={user} topicId={currentTopic.id} lesson={lesson} courseId={courseId} />}
          </div>
          {/* {user.role === UserRole.FreeUser && <TaskAdminPanel lessonId={lessonId} />} */}
          {currentTopic && <SubtopicNavigation currentSubtopic={lesson} currentTopic={currentTopic} courseId={courseId} />}
          {/* <div className='w-full pt-6'>
          <Link className='px-6' to={`/courses/${courseId}`}> Назад До Курсу</Link>
        </div> */}
          <Comments lessonId={lessonId} />
        </div>
      </div>
      {/* <img className='absolute z-0 bottom-0 right-0 rotate-180 w-full h full opacity-50' src="/LessonBg.jpg" alt="" /> */}
    </main>
  );
};

export default Lesson;
