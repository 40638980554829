import { Link } from "react-router-dom";

function CourseCard({ id, name, imageUrl = '' }: { id: string, name: string, imageUrl?: string }) {
    return (
        <Link 
        className="p-2 md:p-10 w-full md:w-1/2 max-w-full md:max-w-sm rounded-3xl overflow-hidden  shadow-lg backdrop-blur-md bg-white/75 transition-transform duration-300 transform hover:scale-110 hover:bg-white transition-all" 
        to={id}>

            <img className="w-full h-[220px] mb-[10px]" src={imageUrl} alt="" />
            <div className="">
                <div className="font-bold text-xl mb-2 text-center">{name}</div>
            </div>
        </Link>
    )

}

export default CourseCard