import React from 'react';
import styles from './MainPage.module.scss'
import Button from '../../components/Button';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { UserRole } from '../../types/User';
import { Link, useNavigate } from 'react-router-dom';
import PricingSection from '../../components/PricingSection';
import CourseBenefitsList from '../../components/CourseBenefitsList';
import CourseTopicsList from '../../components/CourseTopicsList';
import SocialLinks from '../../components/SocialLinks';
import Slides from '../../components/Slides/slides';

function MainPage() {

  const user = useSelector((state: any) => state.auth.user);
  const navigate = useNavigate();


  return (
    <main className="bg-cream">
      <div className="max-w-screen-xl px-8 mx-auto flex flex-col lg:flex-row items-start">
        {/* Left Column */}
        <div className="flex flex-col w-full lg:w-6/12 justify-center lg:pt-24 items-start text-center lg:text-left mb-5 md:mb-0">
          <h1
            className="my-4 text-4xl md:text-5xl font-bold leading-tight text-darken text-center"
          >
            <span className="text-yellow-500">Навчатись</span> тепер легко!
          </h1>
          <p
            className="leading-normal text-lg bd:text-2xl mb-4 "
          >
            Вивчай польську мову з нуля або вдосконалюй свої знання.
          </p>
          <CourseBenefitsList />
          <div
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="700"
            className="w-full flex items-center justify-center lg:justify-start md:space-x-5"
          >
            <Link to="/signup" className="flex items-center gap-2 lg:mx-0 bg-yellow-500 text-white text-sm md:text-xl font-bold rounded-full py-4 px-9 focus:outline-none transform transition hover:scale-105 duration-200 ease-in-out z-10">
              <div className='bg-white min-w-14 min-h-14 rounded-full flex items-center justify-center'>
                <svg className="w-5 h-5 ml-2" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M22.5751 12.8097C23.2212 13.1983 23.2212 14.135 22.5751 14.5236L1.51538 27.1891C0.848878 27.5899 5.91205e-07 27.1099 6.25202e-07 26.3321L1.73245e-06 1.00123C1.76645e-06 0.223477 0.848877 -0.256572 1.51538 0.14427L22.5751 12.8097Z"
                    fill="#23BDEE"
                  />
                </svg>
              </div>
              <div>{user ? 'Пройди безкоштовне заняття' : 'Зареєструйся і пройди безкоштовне заняття'}</div>
            </Link>
          </div>
        </div>
        {/* Right Column */}
        <div className="w-full lg:w-6/12 lg:-mt-10" id="girl">
          <img
            data-aos="fade-up"
            data-aos-once="true"
            className="w-10/12 mx-auto 2xl:-mb-20 lg:min-w[507px] lg:min-h-[780px]  "
            src="/girl.png"
            alt="Girl studying"
          />
          <div data-aos="fade-up" data-aos-delay="10000" data-aos-once="true" className="md:block absolute top-10 right-0 sm:right-24 sm:top-28 md:top-36 md:right-32 lg:top-32 lg:right-16 floating-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              width="60"
              height="60"
              viewBox="0 0 256 256"
              xmlSpace="preserve"
            >
              <defs></defs>
              <g
                style={{
                  stroke: 'none',
                  strokeWidth: 0,
                  strokeDasharray: 'none',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: 10,
                  fill: 'none',
                  fillRule: 'nonzero',
                  opacity: 1,
                }}
                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
              >
                <path
                  d="M 45 90 C 20.147 90 0 69.853 0 45 h 90 C 90 69.853 69.853 90 45 90 z"
                  style={{
                    stroke: 'none',
                    strokeWidth: 1,
                    strokeDasharray: 'none',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: 10,
                    fill: 'rgb(255,213,0)',
                    fillRule: 'nonzero',
                    opacity: 1,
                  }}
                  transform="matrix(1 0 0 1 0 0)"
                  strokeLinecap="round"
                />
                <path
                  d="M 45 0 C 20.147 0 0 20.147 0 45 h 90 C 90 20.147 69.853 0 45 0 z"
                  style={{
                    stroke: 'none',
                    strokeWidth: 1,
                    strokeDasharray: 'none',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: 10,
                    fill: 'rgb(0,91,187)',
                    fillRule: 'nonzero',
                    opacity: 1,
                  }}
                  transform="matrix(1 0 0 1 0 0)"
                  strokeLinecap="round"
                />
              </g>
            </svg></div>
          <div data-aos="fade-up" data-aos-delay="600" data-aos-once="true" className="md:block absolute bottom-32 -left-4 sm:left-2 sm:bottom-20 lg:bottom-24 lg:left-4 floating">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              width="60"
              height="60"
              viewBox="0 0 256 256"
              xmlSpace="preserve"
            >
              <defs></defs>
              <g
                style={{
                  stroke: 'none',
                  strokeWidth: 0,
                  strokeDasharray: 'none',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: 10,
                  fill: 'none',
                  fillRule: 'nonzero',
                  opacity: 1,
                }}
                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
              >
                <path
                  d="M 45 90 C 20.147 90 0 69.853 0 45 h 90 C 90 69.853 69.853 90 45 90 z"
                  style={{
                    stroke: 'none',
                    strokeWidth: 1,
                    strokeDasharray: 'none',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: 10,
                    fill: 'rgb(255, 69, 69)',
                    fillRule: 'nonzero',
                    opacity: 1,
                  }}
                  transform="matrix(1 0 0 1 0 0)"
                  strokeLinecap="round"
                />
                <path
                  d="M 45 0 C 20.147 0 0 20.147 0 45 h 90 C 90 20.147 69.853 0 45 0 z"
                  style={{
                    stroke: 'none',
                    strokeWidth: 1,
                    strokeDasharray: 'none',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: 10,
                    fill: 'rgb(255, 255, 255)',
                    fillRule: 'nonzero',
                    opacity: 1,
                  }}
                  transform="matrix(1 0 0 1 0 0)"
                  strokeLinecap="round"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div className="text-white -mt-14 sm:-mt-24 lg:-mt-30  relative z-0">
        <svg
          className="xl:h-30 xl:w-full"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
            fill="currentColor"
          />
        </svg>
        <div className="bg-white w-full h-20 -mt-px"></div>
      </div>
      <PricingSection />

      <div className='swiperWrapper'>
        <Slides />
      </div>

      <div className='bg-white/60 flex flex-col p-10 pb-14 gap-10 w-full items-center'>
        <h1
          data-aos="fade-right"
          data-aos-once="true"
          className="my-4 text-3xl md:text-5xl font-bold leading-tight text-darken"
        >
          <span className="text-yellow-500">Досі </span>сумніваєшся?
        </h1>
        <div
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-delay="700"
          className="w-full flex items-center justify-center  md:space-x-5"
        >
          <Link to="/signup" className="flex items-center gap-2 lg:mx-0 bg-yellow-500 text-white text-sm md:text-xl font-bold rounded-full py-4 px-9 focus:outline-none transform transition hover:scale-105 duration-200 ease-in-out z-10 relative">
            <div className='bg-white min-w-14 min-h-14 rounded-full flex items-center justify-center'>
              <svg className="w-5 h-5 ml-2" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22.5751 12.8097C23.2212 13.1983 23.2212 14.135 22.5751 14.5236L1.51538 27.1891C0.848878 27.5899 5.91205e-07 27.1099 6.25202e-07 26.3321L1.73245e-06 1.00123C1.76645e-06 0.223477 0.848877 -0.256572 1.51538 0.14427L22.5751 12.8097Z"
                  fill="#23BDEE"
                />
              </svg>
            </div>
            <div>{user ? 'Пройди безкоштовне заняття' : 'Зареєструйся і пройди безкоштовне заняття'}</div>
          </Link>
        </div>
      </div>
      <CourseTopicsList />
      <div className='bg-white flex flex-col py-20 w-full items-center'>
        <div
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-delay="700"
          className="w-full flex items-center justify-center px-5"
        >
          <Link to="/signup" className="flex items-center  gap-2 lg:mx-0 bg-yellow-500 text-white text-sm md:text-xl font-bold rounded-full py-4 px-9 focus:outline-none transform transition hover:scale-105 duration-200 ease-in-out">
            <div className='bg-white min-w-14 min-h-14 rounded-full flex items-center justify-center'>
              <svg className="w-5 h-5 ml-2" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22.5751 12.8097C23.2212 13.1983 23.2212 14.135 22.5751 14.5236L1.51538 27.1891C0.848878 27.5899 5.91205e-07 27.1099 6.25202e-07 26.3321L1.73245e-06 1.00123C1.76645e-06 0.223477 0.848877 -0.256572 1.51538 0.14427L22.5751 12.8097Z"
                  fill="#23BDEE"
                />
              </svg>
            </div>
            <div>{user ? 'Пройди безкоштовне заняття' : 'Зареєструйся і пройди безкоштовне заняття'}</div>
          </Link>
        </div>
      </div>
    </main>
  );
}

export default MainPage;
