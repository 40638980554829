import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addCourse, fetchCourses } from '../../actions/courseActions';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import SpeakWord from '../../components/SpeakWord';
import CourseCard from '../../components/CourseCard';
import Button from '../../components/Button';
import { UserRole } from '../../types/User';

const Courses = () => {
  const courses = useSelector((state: any) => state.course.courses)

  const [name, setName] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const user = useSelector((state: any) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleImageUpload = async (file: File) => {
    const storage = getStorage();
    const storageRef = ref(storage, `course-images/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  };

  const handleSubmit = async () => {
    if (name && image) {
      try {
        const imageUrl = await handleImageUpload(image);
        dispatch(addCourse({ name, imageUrl }));
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  useEffect(() => {
    if (!user) {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    if (user) {
      if (courses.length === 0) {
        dispatch(fetchCourses())
      }
    }
  }, [user])

  if (!user && courses.length === 0) {
    return <>Loading</>
  }

  return (
    <div
    //  style={{ 
    //   backgroundImage: "url('coursesLogo.jpg')",
    //   backgroundSize: "contain",
    //   backgroundRepeat: 'no-repeat',
    //  }}
    >

      {/* {user && user.role === UserRole.Admin &&
        <div className=' flex flex-col'>
          addCourse
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Course Name"
            className='border'
          />
          <input
            type="file"
            onChange={(e) => setImage(e.target.files ? e.target.files[0] : null)}
          />
          <Button variant='secondary' text='Add Course' onClick={handleSubmit} />
        </div>
      } */}
      <div className='bg-main-gradient bg-contain bg-center min-h-[calc(100vh-100px)] flex justify-center'>
        <div className='container w-full px-6 lg:px-10'>
          <div className='flex w-full justify-center items-center md:justify-start flex-col md:flex-row gap-10 py-20'>
            {courses.map((i: any) => {
              if (user?.role !== UserRole.SubUser && user?.role !== UserRole.Admin) {
                return i.free ? (
                  <CourseCard key={i.id} name={i.name} id={i.id} imageUrl={i.imageUrl} />
                ) : (
                  <div className="relative p-2 md:p-10 w-full md:w-1/2 max-w-full md:max-w-sm rounded-3xl overflow-hidden shadow-lg backdrop-blur-md bg-white/75  duration-300 transform    cursor-not-allowed" key={i.id}>
                    <span className=" w-full flex flex-col items-center">
                      <img className="w-full  rounded-xl opacity-20 h-[220px] mb-[10px]" src={i.imageUrl} alt="" />
                      <img className="w-6 h-6 absolute top-2 right-2" src="/locked.png" alt="Locked" />
                      <h1 className="text-start text-lg font-medium text-gray-500/50 cursor-not-allowed">{i.name}</h1>
                    </span>
                    <a className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white bg-yellow-500 transform transition hover:scale-105 duration-200 ease-in-out focus:ring-4 focus:ring-purple-200 font-medium rounded-2xl text-sm md:text-lg px-6 py-3 text-center dark:text-white dark:focus:ring-purple-900"
                      href="/paymentPage">Придбати курс</a>
                  </div>
                );
              } else {
                // Якщо роль subUser, показуємо всі курси
                return <CourseCard key={i.id} name={i.name} id={i.id} imageUrl={i.imageUrl} />;
              }
            })}
          </div>

        </div>
      </div>


    </div>
  );
};

export default Courses;