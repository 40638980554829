import { Node, mergeAttributes, NodeViewProps } from '@tiptap/core'
import { EditorView } from 'prosemirror-view'

interface InputNodeAttributes {
  value: string
  placeholder: string
}

const InputNode = Node.create({
  name: 'inputNode',

  group: 'inline',

  inline: true,

  atom: true,

  addAttributes() {
    return {
      value: {
        default: '',
      },
      placeholder: {
        default: 'Enter text...',
      },
      dataId: {
        default: '', 
      },
    }
  },

  parseHTML() {
    return [
        {
          tag: 'input',
          getAttrs: (element) => ({
            value: element.getAttribute('value'),
            placeholder: element.getAttribute('placeholder'),
            dataId: element.getAttribute('data-id'), 
          }),
        },
      ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['input', mergeAttributes(HTMLAttributes, { type: 'text', 'data-id': HTMLAttributes.dataId })] // Використання data-id
  },

  updateAttributes() {
    return ({ node, updateAttributes }: NodeViewProps) => {
      const input = document.createElement('input') as HTMLInputElement
      input.type = 'text'
      input.value = node.attrs.value || ''
      input.placeholder = node.attrs.placeholder || ''

      const handleInput = (event: Event) => {
        const target = event.target as HTMLInputElement
        
        updateAttributes({ value: target.value })
      }

      const handleBlur = () => {
        updateAttributes({ value: input.value })
      }

      const handleClick = (event: MouseEvent) => {
        event.stopPropagation() // Зупиняє спливання події кліку
      }

      input.addEventListener('input', handleInput)
      input.addEventListener('blur', handleBlur)
      input.addEventListener('click', handleClick)

      return {
        dom: input,
        contentDOM: null, // Не використовується
        destroy() {
          input.removeEventListener('input', handleInput)
          input.removeEventListener('blur', handleBlur)
        }
      }
    }
  },
})

export default InputNode
