import React, { useState } from 'react';
import { uploadVideo } from './uploadVideo';
import { createLesson } from '../../actions/lessonActions';
import { useDispatch } from 'react-redux';

function LessonUpdater({ id }: { id: string }) {
    const [name, setName] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const [progress, setProgress] = useState<number>(0);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (file) {
            try {
                await uploadVideo(file, {
                    onProgress: (progress) => {
                        setProgress(progress);
                    }
                }).then((url) => {
                    dispatch(createLesson({ name, videoUrl: url, id }));
                });
            } catch (error) {
                console.error('Upload failed:', error);
            }
        }
    };

    return (
        <div>
            <button onClick={() => setIsEditing(!isEditing)}>
                {isEditing ? 'Close' : ''} <span role="img" aria-label="edit">✏️</span>
            </button>
            {isEditing && (
                <div>
                    <div>
                        Назва Курсу
                        <input
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                            type="text"
                            className='p-3'
                            placeholder=' Назва Курсу'
                        />
                    </div>
                    <input type="file" accept="video/*" onChange={handleFileChange} />
                    <button onClick={handleUpload}>Upload Video</button>
                    {progress > 0 && progress < 100 && (
                        <div>Upload Progress: {progress.toFixed(2)}%</div>
                    )}
                </div>
            )}
        </div>
    );
}

export default LessonUpdater;
