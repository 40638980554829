import React, { useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { saveTranslationToFirebase } from '../../utils/saveTranslationToFirebase';
import { saveTranslationToFirebaseGroup } from '../saveTranslationToFirebaseGroup';
import { UserRole } from '../../types/User';
import UpdateWordsButton from '../UpdateWordsButton';
import { uploadAudioToFirebase } from '../../utils/uploadAudioToFirebase';

interface SpeakWordProps {
  words: any;
  isGroup?: boolean;
  lang?: string;
  onComplete: () => void;
  taskId: number; // ID документа у Firebase
  subtopicId: string;
  user: any
}

const SpeakWord: React.FC<SpeakWordProps> = ({ words, isGroup = true, lang = 'pl-PL', onComplete, taskId, subtopicId, user }) => {
  const [translations, setTranslations] = useState<{ [key: string]: string }>({});
  const [editingWord, setEditingWord] = useState<string | null>(null);
  const [newTranslation, setNewTranslation] = useState<string>('');

  const speak = (text: string, audioUrl?: string) => {
    if (audioUrl) {
      const audio = new Audio(audioUrl);
      audio.play();
    } else {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = lang;
      speechSynthesis.speak(utterance);
    }
  };
  console.log(words);

  const result = !isGroup && typeof words === 'string' ? words.split(',').map((phrase: any) => {
    const [word, translation] = phrase.split("=");
    return {
      word: word.trim(),
      translation: translation ? translation.trim() : null,
      audioUrl: null
    };
  }) : words as [];

  console.log(result);



  const fetchTranslation = async (word: string) => {
    if (translations[word]) {
      return translations[word];
    }

    try {
      const response = await fetch(`https://api.mymemory.translated.net/get?q=${word}&langpair=${lang}|uk`);
      const data = await response.json();
      const translatedText = data.responseData.translatedText;
      setTranslations((prev) => ({ ...prev, [word]: translatedText }));
      return translatedText;
    } catch (error) {
      console.error('Translation error:', error);
      return 'Translation unavailable';
    }
  };

  const fetchWordsFromFirebase = async () => {

    if (!isGroup && typeof words === 'string') {
      const wordArray = words.split(',');
      const translationMap: { [key: string]: string } = {};
      wordArray.forEach((word) => {
        const [wordText, translation] = word.includes('=') ? word.split('=') : [word, ''];
        translationMap[wordText.trim()] = translation.trim();
      });
      setTranslations(translationMap);
    } else {
      const translationMap: { [key: string]: string } = {};

      // Проходимо по масиву words, який тепер має об'єкти з полями word і translation
      words.forEach((item: any) => {
        if (item.word && item.translation) {
          translationMap[item.word.trim()] = item.translation.trim();
        } else if (item.word) {
          translationMap[item.word.trim()] = ''; // Якщо переклад відсутній
        }
      });

      // Оновлюємо стан перекладів
      setTranslations(translationMap);

    }
  }
  const fetchWordsFromFirebaseGroup = async () => {
    if (isGroup && Array.isArray(words)) { // Перевірка, чи words є масивом
      const translationMap: { [key: string]: string } = {};

      // Ітеруємо через масив words
      words.forEach((group: { category: string; words: Array<{ word: string; translation?: string; audioUrl?: string }> }) => {
        // Ітеруємо через слова в кожній групі
        group.words.forEach((w) => {
          // Якщо слово має переклад, додаємо його до мапи
          translationMap[w.word] = w.translation || ''; // Додаємо переклад, якщо він існує
        });
      });

      // Оновлюємо стейт з новими перекладами
      setTranslations(translationMap);
    }
  };


  useEffect(() => {
    if (isGroup) {
      fetchWordsFromFirebaseGroup();
    } else {

      fetchWordsFromFirebase();
    }
    if (words && words.length > 0) {
      setTimeout(() => {
        onComplete();
      }, 100);
    }
  }, [words]);

  const startEditing = (word: string) => {
    setEditingWord(word);
    setNewTranslation(translations[word] || '');
  };

  const saveTranslation = async () => {
    if (editingWord) {
      const updatedTranslations = { ...translations, [editingWord]: newTranslation };
      setTranslations(updatedTranslations);
      await saveTranslationToFirebase(subtopicId, taskId, editingWord, newTranslation);
      setEditingWord(null);
      setNewTranslation('');
    }
  };

  const saveTranslationGroup = async () => {
    if (editingWord) {
      console.log(editingWord, 'group');

      const updatedTranslations = { ...translations, [editingWord]: newTranslation };
      setTranslations(updatedTranslations);
      await saveTranslationToFirebaseGroup(subtopicId, taskId, editingWord, newTranslation);
      setEditingWord(null);
      setNewTranslation('');
    }
  };



  const cancelEditing = () => {
    setEditingWord(null);
    setNewTranslation('');
  };

  const handleAudioUpload = async (e: React.ChangeEvent<HTMLInputElement>, word: string) => {
    const file = e.target.files?.[0];
    if (file) {
      const audioUrl = await uploadAudioToFirebase(subtopicId, taskId, word, file);
      // Оновлюємо локальний стан, щоб відобразити новий audioUrl
      setTranslations((prev) => ({
        ...prev,
        [word]: audioUrl ?? '',
      }));
    }
  };

  return (
    <>
      {isGroup ? (
        <div className='flex flex-wrap gap-3 justify-center'>
          {words &&
            words.map((i: any, index: number) => (
              <div
                key={i.category + index}
                className='flex items-end gap-2 bg-white rounded-lg p-1 pr-3'
              >
                <h4
                  key={i.category}
                  onClick={() => {
                    if (i.audioUrl) {
                      const audio = new Audio(i.audioUrl);
                      audio.play();
                    } else {
                      speak(i.category[0]);
                    }
                  }}
                  className='cursor-pointer text-2xl bg-yellow-50/40 hover:bg-slate-200 min-w-10 text-center rounded-md'
                >
                  {i.category}
                </h4>
                {i.words.map((w: any, index: number) => (
                  <React.Fragment key={w.word + index} >
                    <span
                      onClick={() => speak(w.word, w.audioUrl)}
                      className='cursor-pointer hover:bg-slate-200 rounded-md'
                      data-tooltip-id={`${w.word}-${index}`}
                      onMouseEnter={() => fetchTranslation(w.word)}
                    >
                      {w.word}
                    </span>
                    <ReactTooltip id={`${w.word}-${index}`} place='top' clickable>
                      {editingWord === w.word ? (
                        <div>
                          <input
                            type='text'
                            value={newTranslation}
                            onChange={(e) => setNewTranslation(e.target.value)}
                            className='border rounded px-1 bg-black'
                          />
                          <button onClick={saveTranslationGroup} className='ml-1 text-green-500'>✔</button>
                          <button onClick={cancelEditing} className='ml-1 text-red-500'>✖</button>

                        </div>
                      ) : (
                        < div className='flex items-center gap-2'>
                          {translations[w.word] || 'Loading...'}
                          {user.role === UserRole.Admin ?
                            <>
                              <button
                                onClick={() => startEditing(w.word)}
                                className='ml-2 text-blue-500 hover:text-blue-700'
                              >
                                ✏️
                              </button>
                              <input
                                type="file"
                                accept="audio/*"
                                onChange={(e) => handleAudioUpload(e, w.word)}
                                className="border rounded px-1"
                              />
                            </>
                            :
                            <></>
                          }
                        </div>
                      )}
                    </ReactTooltip>
                  </React.Fragment>
                ))}
              </div>
            ))}
        </div >
      ) : (
        // Використовуємо масив об'єктів result для відображення слів та перекладів
        <div className='flex flex-wrap gap-3 '>
          {/* <UpdateWordsButton lessonId={subtopicId} taskId={taskId} words={words} /> */}
          {result.map((item: any, index) => (
            <span
              key={item.word + index}
              onClick={() => speak(item.word, item.audioUrl)}
              className='cursor-pointer hover:bg-slate-200 rounded-md'
              data-tooltip-id={`${item.word}-${index}`}
              onMouseEnter={() => fetchTranslation(item.word)}
            >
              {item.word}
              <ReactTooltip id={`${item.word}-${index}`} place='top' clickable>
                {editingWord === item.word ? (
                  <div>
                    <input
                      type='text'
                      value={newTranslation}
                      onChange={(e) => setNewTranslation(e.target.value)}
                      className='border rounded px-1 bg-black'
                    />
                    <button onClick={saveTranslation} className='ml-1 text-green-500'>✔</button>
                    <button onClick={cancelEditing} className='ml-1 text-red-500'>✖</button>
                  </div>
                ) : (
                  <div className='flex items-center gap-2'>
                    {translations[item.word] || 'Loading...'}
                    {user.role === UserRole.Admin ? (

                      <>
                        <button
                          onClick={() => startEditing(item.word)}
                          className='ml-2 text-blue-500 hover:text-blue-700'
                        >
                          ✏️
                        </button>
                        <input
                          type="file"
                          accept="audio/*"
                          onChange={(e) => handleAudioUpload(e, item.word)}
                          className="border rounded px-1"
                        />
                      </>
                    ) : null}
                  </div>
                )}
              </ReactTooltip>
            </span>
          ))}
        </div>
      )}
    </>
  );
};

export default SpeakWord;
