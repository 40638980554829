import { Link } from 'react-router-dom';
import SocialLinks from '../../components/SocialLinks';

function SuccessfulPayment() {
    return (
        <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row items-start p-20">
            <div className="flex flex-col w-full gap-5 justify-center  items-start text-center lg:text-left mb-5 md:mb-0">
                <h1 className="my-4 text-3xl md:text-5xl font-bold leading-tight text-darken text-center lg:text-left">
                    Вітаю Ти придбавав курс
                </h1>
                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    Якщо ти придбав курс Стандарт то переходь зразу до навчання!!!!
                </h2>

                <div>
                    <Link to="/courses" className='text-white bg-yellow-500 transform transition hover:scale-105 duration-200 ease-in-out focus:ring-4 focus:ring-purple-200 font-medium rounded-2xl text-sm md:text-lg px-5 py-2.5 text-center dark:text-white dark:focus:ring-purple-900 mt-6'>Перейти до навчання</Link>
                </div>
                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    Якщо ти придбав курс ПРЕМІУМ або VIP то негайно звернись до мене за цими посиланнями і також переходь до навчання
                </h2>
                <SocialLinks />
                <div>
                    <Link to="/courses" className='text-white bg-yellow-500 transform transition hover:scale-105 duration-200 ease-in-out focus:ring-4 focus:ring-purple-200 font-medium rounded-2xl text-sm md:text-lg px-5 py-2.5 text-center dark:text-white dark:focus:ring-purple-900 mt-6'>Перейти до навчання</Link>
                </div>
            </div>
        </div>
    );
}

export default SuccessfulPayment;