import React, { useEffect, useState } from "react";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { UserRole } from "../../types/User";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Інтерфейс користувача
export interface User {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  subscription?: {
    validUntil: string | null;
  };
  metadata: {
    createdAt: string;
  };
}

const AdminPanel: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const currentUser = useSelector((state: any) => state.auth.user);

  const getUsers = async (): Promise<User[]> => {
    const usersCollection = collection(firestore, "users");
    const snapshot = await getDocs(usersCollection);
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      metadata: doc.data().metadata || { createdAt: "" }, // Додаємо метадані, якщо їх немає
    })) as User[];
  };

  const changeUserRole = (user: User, newRole: UserRole): User => {
    return {
      ...user,
      role: newRole,
      subscription: newRole === UserRole.SubUser && selectedDate
        ? { validUntil: selectedDate.toISOString() }
        : undefined,
    };
  };

  const updateUserRole = async (user: User, newRole: UserRole) => {
    try {
      const updatedUser = changeUserRole(user, newRole);
      const userDocRef = doc(firestore, "users", user.id);

      await updateDoc(userDocRef, {
        role: updatedUser.role,
        subscription: updatedUser.subscription || null,
      });

      setUsers((prevUsers) =>
        prevUsers.map((u) => (u.id === user.id ? updatedUser : u))
      );
      setSelectedDate(null);
    } catch (error) {
      console.error("Error updating user role: ", error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userData = await getUsers();
        userData.sort((a, b) => new Date(b.metadata.createdAt).getTime() - new Date(a.metadata.createdAt).getTime());
        setUsers(userData);
      } catch (error) {
        console.error("Error fetching users: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter((user) =>
      user.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchTerm, users]);

  if (loading) {
    return <div>Loading users...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4 text-center">Список Користувачів</h2>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Пошук по email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <DatePicker
          selected={selectedDate}
          onChange={(date: Date | null) => setSelectedDate(date)}
          dateFormat="yyyy-MM-dd"
          placeholderText="Оберіть дату підписки"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
        />
      </div>
      <div>
        <div>
          Загально зареєстрованих користувачів:{filteredUsers?.length}
        </div>
        <div>
          Підписники:{filteredUsers.filter((i: any) => i.role === 'subUser')?.length}
        </div>
      </div>
      <ul className="space-y-4">
        {filteredUsers.map((user) => (
          <li
            key={user.id}
            className="flex justify-between items-center bg-white shadow-md rounded-lg p-4 hover:bg-gray-100 transition-colors duration-300"
          >
            <div>
              <p className="text-lg font-semibold">{user.name}</p>
              <p className="text-sm text-gray-600">{user.email}</p>
              <p className="text-sm">
                Role:{" "}
                <span
                  className={`${user.role === UserRole.SubUser
                    ? "text-green-500"
                    : "text-blue-500"
                    } font-medium`}
                >
                  {user.role}
                </span>
              </p>
              {user.role === UserRole.SubUser && user.subscription?.validUntil && (
                <p className="text-sm text-gray-500">
                  Підписка Дійсна до:{" "}
                  <span className="text-red-500">{user.subscription.validUntil}</span>
                </p>
              )}
              <p className="text-sm text-gray-500">
                Зареєстрований:{" "}
                <span className="text-gray-800">{new Date(user.metadata.createdAt).toLocaleDateString()}</span>
              </p>
            </div>

            <div className="flex space-x-2">
              {currentUser.email !== user.email && (user.role !== UserRole.SubUser ? (
                <button
                  onClick={() => updateUserRole(user, UserRole.SubUser)}
                  className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300"
                  disabled={!selectedDate} // Деактивація кнопки, якщо дата не обрана
                >
                  Додати підписку
                </button>
              ) : (
                <button
                  onClick={() => updateUserRole(user, UserRole.FreeUser)}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  Видалити підписку
                </button>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminPanel;
