import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { UserRole } from '../../types/User';
import SocialLinks from '../SocialLinks';
import { Tooltip } from 'react-tooltip';
import { getCourseDetails } from '../../types/subscriptionType';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state: any) => state.auth.user);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const logoutHandler = () => {
    navigate('/login')
    auth.signOut().then(() => {
      navigate('/')
      console.log('User signed out.');
    }).catch((error) => {
      console.error('Error signing out: ', error);
    });
  }

  const navLinkClass = "px-4 py-2 mt-2 text-sm bg-transparent md:mt-0 md:ml-0 transition focus:outline-none focus:shadow-outline hover:scale-105 active:text-gray-700"

  const getClassName = (paths: string[]) => {
    return ({ isActive }: { isActive: boolean }): string =>
      [isActive || paths.includes(location.pathname) ? '!text-gray-700' : "", navLinkClass].join(" ");
  };

  return (
    <div className="w-full text-gray-700 bg-cream">
      <div className="flex flex-col max-w-screen-xl px-8 mx-auto md:items-center md:justify-between md:flex-row">
        <div className="flex flex-row items-center justify-between py-6">
          <div className="relative flex items-center gap-4">
            <a
              href="/"
              className="text-lg relative z-50 font-bold tracking-widest text-gray-900 rounded-lg focus:outline-none focus:shadow-outline"
            >
              <img className='w-16 lg:w-20' src="/logo.png" alt="logo" />
            </a>
            <SocialLinks />
          </div>
          <button
            className="rounded-lg md:hidden focus:outline-none focus:shadow-outline"
            onClick={toggleMenu}
          >
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              )}
            </svg>
          </button>
        </div>
        <nav
          className={`flex flex-col flex-grow md:items-center pb-4 md:pb-0 md:flex md:justify-end md:flex-row origin-top duration-300 ${isOpen ? 'h-full scale-y-100' : 'h-0 md:h-auto scale-y-0 md:scale-y-100'
            }`}
        >
          <NavLink
            className={({ isActive }) =>
              [isActive ? "!text-gray-700" : "", navLinkClass].join(" ")
            }
            to="/"
          >
            Головна
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              [isActive ? "!text-gray-700" : "", navLinkClass].join(" ")
            }
            to="/courses"
          >
            Курси
          </NavLink>
          {user && user.role === UserRole.Admin &&
            <NavLink
              className={({ isActive }) =>
                [isActive ? "!text-gray-700" : "", navLinkClass].join(" ")
              }
              to="/adminPanel"
            >
              Адмін Панель
            </NavLink>
          }
          <NavLink
            className={({ isActive }) =>
              [isActive ? "!text-gray-700" : "", navLinkClass].join(" ")
            }
            to="/about"
          >
            Про мене
          </NavLink>
          {user && <>
            <svg
              height="20px"
              width="20px"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 60.671 60.671"
              xmlSpace="preserve"
              data-tooltip-id="user"
            >
              <g>
                <g>
                  <ellipse style={{ fill: '#010002' }} cx="30.336" cy="12.097" rx="11.997" ry="12.097" />
                  <path
                    style={{ fill: '#010002' }}
                    d="M35.64,30.079H25.031c-7.021,0-12.714,5.739-12.714,12.821v17.771h36.037V42.9
            C48.354,35.818,42.661,30.079,35.64,30.079z"
                  />
                </g>
              </g>
            </svg>
            <Tooltip className='opaque' id="user" place="top" clickable >
              <div>
                {user.email}
              </div>
              <div>
                {getCourseDetails(user?.subscription?.type)}
              </div>
              <div>
                Підписка дійсна до {new Date(user?.subscription?.validUntil).toLocaleDateString('uk-UA', { timeZone: 'Europe/Kyiv' })}
              </div>
              <div>
                За інформацією звертайтесь за цими контактами
              </div>
              <div>
                <SocialLinks size='25px'/>
              </div>
            </Tooltip>
          </>
          }
          {user ?
            <button className={`${navLinkClass} px-10 py-3 mt-2 text-sm text-center bg-yellow-500 text-white rounded-full md:mt-0 md:ml-4`} onClick={logoutHandler}>Вийти</button>
            :
            <NavLink
              className={`${getClassName(['/signup'])} px-10 py-3 mt-2 text-sm text-center bg-yellow-500 text-white rounded-full md:mt-0 md:ml-4 hover:scale-105 transition`}
              to="/login"
            >
              Увійти
            </NavLink>
          }
          {/* <button className={navLinkClass} onClick={logoutHandler}>Вийти</button> */}

        </nav>
      </div>
    </div>
  );
};

export default Navbar;
