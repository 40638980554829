import React, { useEffect, useState, useMemo } from 'react';
import DocxViewer from "../../components/DocxViewer";
import SpeakWord from '../../components/SpeakWord';
import ListenTask from '../../components/ListenTask';
import ImageMatchTask from '../../components/ImageMatchTask';
import DisplayChooseCorrectAnswer from '../../components/AddChooseCorrectAnswer/DisplayChooseCorrectAnswer';
import DisplayComponent from '../../components/AddFillTheBlanksTask/DisplayComponent';
import ListenAudioTask from '../../components/ListenAudioTask';
import { TaskType } from './TaskCreator';

const TaskRenderer = ({ lesson, topicId, user, courseId }: any) => {
    const [completedTasks, setCompletedTasks] = useState<boolean[]>([]);

    useEffect(() => {
        setCompletedTasks(new Array(lesson.tasks.length).fill(false));
    }, [lesson]);


    const handleTaskComplete = (index: number) => {
        setCompletedTasks(prev => {
            const newCompletedTasks = [...prev];
            newCompletedTasks[index] = true;
            return newCompletedTasks;
        });
    };


    const renderTask = (task: any, index: number) => {
        const canRenderTask = index === 0 || completedTasks[index - 1]; // Перевірка, чи можна рендерити завдання

        if (!canRenderTask) return null;

        switch (task.type) {
            case TaskType.FillTheBlanks:
                return (
                    <DisplayComponent
                        courseId={courseId}
                        topicId={topicId}
                        user={user}
                        subtopicId={lesson.id}
                        taskId={task.id}
                        htmlContent={task.taskWithBlanks.text}
                        answers={task.taskWithBlanks.answers}
                        onComplete={() => handleTaskComplete(index)} // Передати функцію завершення
                    />
                );
            case TaskType.WriteWhatYouHear:
                return (
                    <ListenTask
                        words={task.words}
                        courseId={courseId}
                        topicId={topicId}
                        user={user}
                        subtopicId={lesson.id}
                        taskId={task.id}
                        onComplete={() => handleTaskComplete(index)} // Передати функцію завершення
                    />
                );
            case TaskType.DownloadFile:
                return (
                    <div>
                        <DocxViewer filePath={task.fileUrl} onComplete={() => handleTaskComplete(index)} />
                    </div>
                );
            case TaskType.ChooseCorrectAnswer:
                return (
                    <DisplayChooseCorrectAnswer
                        courseId={courseId}
                        topicId={topicId}
                        user={user}
                        subtopicId={lesson.id}
                        taskId={task.id}
                        questions={task.chooseCorrectAnswer}
                        onComplete={() => handleTaskComplete(index)} // Передати функцію завершення
                    />
                );
            case TaskType.ChooseCorrectImage:
                return (
                    <ImageMatchTask
                        courseId={courseId}
                        topicId={topicId}
                        user={user}
                        subtopicId={lesson.id}
                        taskId={task.id}
                        questions={task.chooseCorrectImage}
                        onComplete={() => handleTaskComplete(index)} // Передати функцію завершення
                    />
                );
            case TaskType.ListenAudio:
                return (
                    <ListenAudioTask
                        user={user}
                        task={task}
                        index={index}
                        subtopicId={lesson.id}
                        onComplete={() => handleTaskComplete(index)}
                    />
                );
            case TaskType.SpeakWords:
                return (
                    <SpeakWord
                        user={user}
                        subtopicId={lesson.id}
                        taskId={task.id}
                        isGroup={false}
                        words={task.wordsToSpeak}
                        onComplete={() => handleTaskComplete(index)} // Передати функцію завершення
                    />
                );
            case TaskType.WordGroups:
                return (
                    <SpeakWord
                        user={user}
                        subtopicId={lesson.id}
                        taskId={task.id}
                        words={task.wordsWithCategories}
                        onComplete={() => handleTaskComplete(index)} // Передати функцію завершення
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className='flex flex-col'>
            {lesson && lesson.tasks.map((task: any, index: number) => (
                <div className='flex flex-col items-center mb-16' key={task.id || index}>
                    <div className='flex items-start lg:items-start gap-3 mb-6 w-full'>
                        <h3 id={task.id} className='text-xl lg:text-2xl font-medium whitespace-nowrap'>Завдання {index + 1}:</h3>
                        <span className='text-base lg:text-lg'>{task.name}</span>
                    </div>
                    {renderTask(task, index)}
                </div>
            ))}
        </div>
    );
};

export default TaskRenderer;
