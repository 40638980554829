import { createAction } from '@reduxjs/toolkit';
import { RequestStatus } from '../types/RequestStatus';
import User from '../types/User';

interface AuthPayload {
    user?: any;
    error?: string;
}
export const init  = createAction('auth/init');

export const loginRequest = createAction<{ email: string, password: string }>('auth/loginRequest');
export const loginRequestStatus = createAction<{ requestStatus: RequestStatus }>('auth/loginRequestStatus');
export const loginAction = createAction<{ user: User }>('auth/loginAction');

export const continueWithGoogleRequest  = createAction('auth/continueWithGoogleRequest');


export const signupRequest = createAction<{ email: string, password: string }>('auth/signupRequest');
export const registrationRequestStatus = createAction<{ requestStatus: RequestStatus }>('auth/registrationRequestStatus');



export const signupAction = createAction<{
    error?: null;
    user?: null; status: RequestStatus, payload?: AuthPayload
}>('auth/signupAction');


export const logoutRequest = createAction('auth/logoutRequest');

export const logoutAction = createAction<{
    error?: null; status: RequestStatus, payload?: AuthPayload
}>('auth/logoutAction');

export const setUser = createAction<{ user: User | null }>('auth/setUser');
export const passwordResetRequest = createAction<{email: string}>('auth/passwordResetRequest');

