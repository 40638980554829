import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

export const uploadAudioToFirebase = async (
    lessonId: string,
    taskId: number,
    word: string,
    audioFile: File
) => {
    try {
        // Отримуємо посилання на Firebase Storage
        const storage = getStorage();
        const storageRef = ref(storage, `audio/${lessonId}/${taskId}/${word}.mp3`);

        // Завантажуємо файл
        const uploadResult = await uploadBytes(storageRef, audioFile);

        // Отримуємо URL завантаженого аудіофайлу
        const audioUrl = await getDownloadURL(uploadResult.ref);

        // Оновлюємо документ у Firestore з новим audioUrl
        await updateAudioUrlInFirestore(lessonId, taskId, word, audioUrl);

        console.log('Audio file uploaded successfully:', audioUrl);
        return audioUrl; // Повертаємо URL аудіофайлу
    } catch (error) {
        console.error('Error uploading audio to Firebase:', error);
    }
};

const updateAudioUrlInFirestore = async (
    lessonId: string,
    taskId: number,
    word: string,
    audioUrl: string
) => {
    try {
        // Отримуємо документ уроку
        const docRef = doc(firestore, 'lessons', lessonId);
        const lessonDoc = await getDoc(docRef);

        if (!lessonDoc.exists()) {
            console.error('Lesson document not found');
            return;
        }

        const lessonData = lessonDoc.data();

        // Знаходимо завдання та оновлюємо audioUrl
        const tasks = lessonData.tasks.map((task: any) => {
            if (task.id === taskId && task.wordsWithCategories) {
                task.wordsWithCategories = task.wordsWithCategories.map((category: any) => {
                    category.words = category.words.map((w: any) => {
                        if (w.word.trim() === word) {
                            return { ...w, audioUrl }; // Оновлюємо audioUrl для слова
                        }
                        return w;
                    });
                    return category;
                });
            } else if (task.id === taskId && task.wordsToSpeak) {
                task.wordsToSpeak = task.wordsToSpeak.map((w: any) => {
                    if (w.word.trim() === word) {
                        return { ...w, audioUrl }; // Оновлюємо audioUrl для слова
                    }
                    return w;
                });
            }
            return task;
        });

        // Оновлюємо документ у Firebase
        await updateDoc(docRef, { tasks });
        console.log(`Audio URL for ${word} updated successfully`);
    } catch (error) {
        console.error('Error updating audio URL in Firebase:', error);
    }
};
