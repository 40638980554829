import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addTopic, fetchCourseById } from '../../actions/courseActions';
import { RequestStatus } from '../../types/RequestStatus';
import Button from '../../components/Button';
import TopicCard from '../../components/TopicCard';
import { UserRole } from '../../types/User';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { app } from '../../firebase';
import { setUser } from '../../actions/authActions';

const Course = () => {
  const { courseId = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.auth.user);
  const requestStatus = useSelector((state: any) => state.auth.requestStatus);
  const selectedCourse = useSelector((state: any) => state.course.selectedCourse);
  const topics = useSelector((state: any) => state.course.selectedCourse?.topics);

  const [topicName, setTopicName] = useState('')

  const db = getFirestore(app);

  const initializeUserProgressForCourse = async (
    userId: string,
    courseId: string,
    topics: any[]
  ) => {
    const progressRef = doc(db, 'users', userId);

    const initialCourseProgress = {
      courseProgress: 0,
      topics: topics.map((topic, topicIndex) => ({
        id: topic.id,
        progress: 0,
        isCompleted: false,
        name: topic.name,
        subtopics: topic.subtopics.map((subtopic: { id: string; imgUrl: string, name: string }, subtopicIndex: number) => ({
          id: subtopic.id,
          progress: 0,
          isCompleted: false,
          imgUrl:subtopic.imgUrl,
          name: subtopic.name,
          // Відкриваємо тільки перший сабтопік у першому топіку
          isLocked: topicIndex === 0 && subtopicIndex === 0 ? false : true
        }))
      }))
    };

    await setDoc(progressRef, { courses: { [courseId]: initialCourseProgress } }, { merge: true });

    dispatch(setUser({
      user: {
        ...user,
        courses: { [courseId]: initialCourseProgress },
      },
    }));
    
  };

  useEffect(() => {
    if (!user && requestStatus === RequestStatus.Success) {
      navigate(`/login`)
    }
  }, [requestStatus]);


  const getUserProgress = async (userId: string) => {

    const progressRef = doc(db, 'users', userId);
    const progressDoc = await getDoc(progressRef);
    return progressDoc.exists() ? progressDoc.data() : null;
  };


  
  useEffect(() => {
    dispatch(fetchCourseById(courseId || ''));
  }, [courseId]);
  
  useEffect(() => {
    if (user && courseId && topics) {
      const initializeProgress = async () => {
        const userProgress = await getUserProgress(user.userId);
        if (!userProgress?.courses?.[courseId]) {
          await initializeUserProgressForCourse(user.userId, courseId, topics);
        }
      };

      initializeProgress();
    }
  }, [user, courseId, topics]);

  if (!selectedCourse) {
    return null
  }

  function addTopicHandler(): void {
    dispatch(addTopic({ courseId: selectedCourse.id, topicName }));
  }

  return (
    <div>
      <div className='relative bg-main-gradient z-10 bg-contain bg-center min-h-[calc(100vh-100px)] flex flex-col items-center py-12'>
        <div className='container w-full px-1 lg:px-10'>
          <h1
            className='text-3xl lg:text-5xl text-center'>
            {selectedCourse.name}
          </h1>

          {/* {user && user.role === UserRole.Admin &&
            <div>
              <div className=' flex flex-col'>
                <input
                  type="text"
                  value={topicName}
                  onChange={(e) => setTopicName(e.target.value)}
                  placeholder="Назва Теми"
                  className='border'
                />
                <Button variant='secondary' text='Додати Тему' onClick={addTopicHandler} />
              </div>
            </div>
          } */}
          {user && user.courses?.[courseId]?.topics.map((i: any, index: number) => <TopicCard key={i.id} name={i.name} id={i.id} courseId={selectedCourse.id} index={index} userSubtopics={i.subtopics} />)}
        </div>
        {selectedCourse.free && <div className='w-full px-1 lg:px-24 mt-20'>Щоб відкрити наступний урок, виконай завдання в минулому.</div>}
      </div>
    </div>
  );
};

export default Course;