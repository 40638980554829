import React, { useEffect, useState } from 'react';
import { updateSubtopicTask } from '../../utils/updateSubtopicTask';
import styles from './TaskList.module.scss'; // Імпортуємо модуль SCSS
import { useDispatch } from 'react-redux';

interface Question {
  question: string;
  options: string[];
  correctAnswer: string;
}

interface TaskListProps {
  user: any;
  courseId: string;
  topicId: string;
  subtopicId: string;
  taskId: string;
  questions: Question[];
  onComplete: () => void;
}

const TaskList: React.FC<TaskListProps> = ({ user, courseId, topicId, subtopicId, taskId, questions, onComplete }) => {
  const [answers, setAnswers] = useState<string[]>(new Array(questions.length).fill(''));
  const [validationResults, setValidationResults] = useState<(boolean | null)[]>(new Array(questions.length).fill(null));
  const [isFetched, setIsFetched] = useState(false);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);

  const dispatch = useDispatch();
  // Завантаження попередніх відповідей
  useEffect(() => {
    const fetchUserAnswers = async () => {
      try {
        if (user) {
          const courseUserData = user.courses?.[courseId];
          if (courseUserData) {
            const topicData = courseUserData.topics.find((topic: any) => topic.id === topicId);
            const subtopicData = topicData?.subtopics?.find((subtopic: any) => subtopic.id === subtopicId);
            const taskData = subtopicData?.tasks?.find((task: any) => task.id === taskId);

            if (taskData && taskData.userInputs) {
              setAnswers(taskData.userInputs);

              // Перевіряємо відповіді та встановлюємо стан для правильності
              const initialValidation = taskData.userInputs.map((input: string, index: number) => {
                return input === questions[index].correctAnswer;
              });
              setValidationResults(initialValidation);
              setIsTaskCompleted(taskData.isCompleted)
            }
            setIsFetched(true);
          }
        }
      } catch (error) {
        console.error('Error fetching user answers:', error);
      }
    };

    fetchUserAnswers();
  }, [user, courseId, topicId, subtopicId, taskId, questions]);

  
  useEffect(() => {
    const updateTask = async () => {
      if (isFetched) {
         updateSubtopicTask(user, courseId, topicId, subtopicId, taskId, isTaskCompleted ?? false, answers, dispatch).then(()=>{onComplete()});;
      }
    };
    updateTask();
  }, [isFetched]);

  const handleAnswerChange = (index: number, answer: string) => {
    const newAnswers = [...answers];
    newAnswers[index] = answer;
    setAnswers(newAnswers);

    // Скидаємо стан перевірки для цього індексу
    const newValidation = [...validationResults];
    newValidation[index] = null;
    setValidationResults(newValidation);
  };

  const handleSubmit = async () => {
    try {
      await updateSubtopicTask(user, courseId, topicId, subtopicId, taskId, true, answers, dispatch);

      // Перевірка відповідей та оновлення стану
      const newValidationResults = questions.map((task, index) => {
        return task.correctAnswer === answers[index];
      });
      setValidationResults(newValidationResults);
    } catch (error) {
      console.error('Error saving user answers:', error);
    }
  };

  return (
    <>
      <div className='flex flex-wrap gap-6 justify-center lg:justify-start mb-6'>
        {questions.map((task, index) => (
          <div className='text-center flex flex-col justify-end' key={index}>
            <p className='font-bold first-letter:uppercase mb-2 w-[200px]' dangerouslySetInnerHTML={{ __html: task.question.replace(/\n/g, '<br/>') }} />
            <select
              className={`rounded-2xl p-2 border-none hover:bg-slate-50 cursor-pointer  max-w-[300px] ${validationResults[index] === true && isTaskCompleted ? styles.correct : validationResults[index] === false && isTaskCompleted ? styles.incorrect : ''}`}
              value={answers[index]}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
            >
              <option value="" disabled>Вибери відповідь</option>
              {task.options.map((option, optIndex) => (
                option.length && <option key={optIndex} value={option}>{option}</option>
              ))}
            </select>
          </div>
        ))}
      </div>
      <button className='p-3 text-base rounded-2xl bg-green-400/60 hover:bg-green-400' onClick={handleSubmit}>Перевірити Відповіді</button>
    </>
  );
}

export default TaskList;
