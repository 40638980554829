import React, { useState } from "react";
import { uploadFile } from "../../pages/Lesson/uploadFile";




interface UploadFileProps {
    onAddWord: (word: { word: string; audioUrl: string | null }) => void;
}

const AddWordsComponent: React.FC<UploadFileProps> = ({ onAddWord }) => {
    const [wordsForReading, setWordsForReading] = useState("");
    const [audioFile, setAudioFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);

    const handleAudioFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setAudioFile(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (wordsForReading.trim() && audioFile) {
            setUploading(true);
            try {
                const downloadURL = await uploadFile(audioFile, {
                    onProgress: (progress) => {
                        console.log(`Upload is ${progress}% done`);
                    },
                }, 'audios/');

                onAddWord({
                    word: wordsForReading.trim(),
                    audioUrl: downloadURL,
                });

                setWordsForReading("");
                setAudioFile(null);
            } catch (error) {
                alert("Failed to upload file.");
                console.error(error);
            } finally {
                setUploading(false);
            }
        } else if (wordsForReading.trim() && !audioFile) {
            onAddWord({
                word: wordsForReading.trim(),
                audioUrl: null,
            });

            setWordsForReading("");
            setAudioFile(null);
        } else {
            alert("Please enter a word and select an audio file.");
        }
    };

    return (
        <div>
            <label htmlFor="words">Слово</label>
            <input
                value={wordsForReading}
                onChange={(e) => setWordsForReading(e.currentTarget.value)}
                className="w-full"
                name="Words"
                id="words"
            />
            <label>Аудіо якщо потрібно</label>
            <input
                type="file"
                id="fileUpload"
                onChange={handleAudioFileChange}
                className="w-full border-none p-2"
            />
            <button onClick={handleUpload} className="mt-2 bg-white rounded-md p-2 hover:bg-yellow-200/50" disabled={uploading}>
                {uploading ? 'Слово додається' : 'Дадати слово'}
            </button>
        </div>
    );
};

export default AddWordsComponent;
