import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signInSchema, signUpSchema } from '../../yup/signUpInSchema';
import Button from '../../components/Button';
import { loginRequest, signupRequest, continueWithGoogleRequest } from '../../actions/authActions';
import PasswordResetForm from '../PasswordResetForm';

interface Props {
    isLoginPage: boolean;
}
interface FormValues {
    email: string,
    password: string,
    confirmPassword: string,
}

function LoginSignUpForm({ isLoginPage }: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.auth.user);
    const { courseId } = useParams();

    const [showPasswordReset, setShowPasswordReset] = useState(false); // стан для форми скидання пароля

    const handleSubmit = (values: FormValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        const { email, password } = values;

        if (isLoginPage) {
            dispatch(loginRequest({ email, password }));
        } else {
            dispatch(signupRequest({ email, password }));
        }
        setSubmitting(false);
    };

    const continueWithGoogle = () => {
        dispatch(continueWithGoogleRequest());
    };

    useEffect(() => {
        if (user) {
            navigate('/courses');
        }
    }, [user]);

    return (
        <main className="bg-no-repeat bg-contain bg-center flex w-full justify-center items-center h-[calc(100vh-100px)]">
            <div className="bg-main-gradient w-full h-full flex items-center justify-center">
                <div className="container max-w-lg flex flex-col items-center">
                    {showPasswordReset ? (
                        <>
                            <PasswordResetForm />
                            <button
                                type="button"
                                className="inline-block align-baseline font-bold text-sm text-blue-500/80 hover:text-blue-800"
                                onClick={() => setShowPasswordReset(false)}
                            >
                                Назад
                            </button>
                        </>
                    ) : (
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                                confirmPassword: '',
                            }}
                            validationSchema={isLoginPage ? signInSchema : signUpSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form
                                    className="backdrop-blur-sm bg-[#fffaed]/50 shadow-md px-8 pt-6 pb-8 mb-4 w-full rounded-3xl flex flex-col items-center justify-center"
                                >
                                    <h1 className="text-6xl my-6 text-center">{isLoginPage ? 'Увійти' : 'Реєстрація'}</h1>
                                    {isLoginPage && (
                                        <div className='flex gap-1'>
                                            <h3 className='mb-6'>Вище не зареєстровані?</h3>
                                            <Link className='text-blue-500/80 hover:text-blue-800' to="/signup">Реєструйся Безкоштовно</Link>
                                        </div>
                                    )}
                                    <div className="mb-4 w-full">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                            Електронна адреса
                                        </label>
                                        <Field
                                            className="shadow appearance-none border-gray-100 bg-white/85 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Електронна адреса"
                                        />
                                        <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic" />
                                    </div>
                                    <div className="mb-4 w-full">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                            Пароль
                                        </label>
                                        <Field
                                            className="shadow-lg border-gray-100 bg-white/85 appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                            type="password"
                                            id="password"
                                            name="password"
                                            placeholder="******************"
                                        />
                                        <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic" />
                                    </div>

                                    {!isLoginPage && (
                                        <div className="mb-6 w-full">
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                                                Повторіть пароль
                                            </label>
                                            <Field
                                                className="shadow appearance-none border border-gray-100 bg-white/85 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                                type="password"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                placeholder="******************"
                                            />
                                            <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-xs italic" />
                                        </div>
                                    )}
                                    <Button
                                        className='text-base w-full mb-6'
                                        text={isLoginPage ? 'Увійти' : 'Зареєструватись'}
                                        variant='secondary'
                                        disabled={isSubmitting}
                                        type='submit'
                                    />

                                    <div className='text-center relative mb-6 w-full'>
                                        <div className="block">Або</div>
                                        <div className="absolute bg-gray-700/10 h-[1px] bottom-[10px] left-0 w-[45%]"></div>
                                        <div className="absolute bg-gray-700/10 h-[1px] bottom-[10px] right-0 w-[45%]"></div>
                                    </div>
                                    <button
                                        onClick={continueWithGoogle}
                                        type='button'
                                        disabled={isSubmitting}
                                        className="transition duration-200 ease-in-out mb-6 flex items-center bg-white border-none rounded-lg shadow-md max-w-xs px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    >
                                        <svg className="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 0 48 48" version="1.1">
                                            <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g id="Color-" transform="translate(-401.000000, -860.000000)">
                                                    <g id="Google" transform="translate(401.000000, 860.000000)">
                                                        <path
                                                            d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
                                                            id="Fill-1" fill="#FBBC05"
                                                        ></path>
                                                        <path
                                                            d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
                                                            id="Fill-2" fill="#EB4335"
                                                        ></path>
                                                        <path
                                                            d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
                                                            id="Fill-3" fill="#34A853"
                                                        ></path>
                                                        <path
                                                            d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
                                                            id="Fill-4" fill="#4285F4"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>Продовжити з Google</span>
                                    </button>
                                    <div className="flex items-center justify-between w-full">
                                        {isLoginPage ? (
                                            <button
                                                type="button"
                                                className="inline-block align-baseline font-bold text-sm text-blue-500/80 hover:text-blue-800"
                                                onClick={() => setShowPasswordReset(true)}
                                            >
                                                Забули пароль?
                                            </button>
                                        ) : (
                                            <div className='flex'>
                                                <h3>Ви вже зареєстровані? </h3><Link className='text-blue-500/80 hover:text-blue-800' to="/login">Увійти</Link>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </div>
        </main>
    );
}

export default LoginSignUpForm;
