import { doc, getDoc, updateDoc } from "firebase/firestore";
import { setUser } from "../actions/authActions";
import { firestore } from "../firebase";

export async function updateSubtopicTask(
  user: any,
  courseId: string,
  topicId: string,
  subtopicId: string,
  taskId: string,
  isCompleted: boolean,
  userInputs: string[],
  dispatch: any,
) {
  
  const userRef = doc(firestore, "users", user.userId);
  
  // Отримуємо документ користувача
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    const userData = userSnap.data();
    
    // Доступ до курсу користувача
    const courseUserData = userData.courses?.[courseId];
    if (!courseUserData) {
      console.log("Course not found for the user");
      return;
    }

    // Оновлюємо topics в курсі
    const updatedTopics = courseUserData.topics.map((topic: any, topicIndex: number) => {
      if (topic.id === topicId) {
        // Оновлюємо subtopics в темі
        topic.subtopics = topic.subtopics?.map((subtopic: any, subtopicIndex: number) => {
          if (subtopic.id === subtopicId) {
            // Якщо tasks не існує, ініціалізуємо його як порожній масив
            if (!subtopic.tasks) {
              subtopic.tasks = [];
            }

            // Шукаємо існуюче завдання
            const existingTaskIndex = subtopic.tasks.findIndex((task: any) => task.id === taskId);

            if (existingTaskIndex !== -1) {
              // Оновлюємо існуюче завдання
              subtopic.tasks[existingTaskIndex] = {
                ...subtopic.tasks[existingTaskIndex],
                isCompleted,
                userInputs, // Додаємо відповіді користувача
              };
            } else {
              // Додаємо нове завдання, якщо воно не існує
              subtopic.tasks = [
                ...subtopic.tasks, // Зберігаємо всі існуючі завдання
                {
                  id: taskId,
                  isCompleted,
                  userInputs, // Додаємо відповіді користувача
                },
              ];
            }

            // Оновлюємо прогрес сабтопіка і позначаємо як завершений, якщо завдання виконані
            const allTasksCompleted = subtopic.tasks.every((task: any) => task.isCompleted);
            subtopic.isCompleted = allTasksCompleted;
            subtopic.progress = allTasksCompleted ? 100 : subtopic.progress;

            // Якщо сабтопік завершено, відкриваємо наступний сабтопік
            if (subtopic.isCompleted) {
              if (subtopicIndex < topic.subtopics.length - 1) {
                // Відкриваємо наступний сабтопік у цьому ж топіку
                topic.subtopics[subtopicIndex + 1].isLocked = false;
              } else if (topicIndex < courseUserData.topics.length - 1) {
                // Відкриваємо перший сабтопік у наступному топіку
                const nextTopic = courseUserData.topics[topicIndex + 1];
                if (nextTopic && nextTopic.subtopics.length > 0) {
                  nextTopic.subtopics[0].isLocked = false;
                }
              }
            }
          }
          return subtopic;
        }) ?? [];
      }
      return topic;
    });

    // Оновлюємо курс з оновленими topics
    const updatedCourses = {
      ...userData.courses,
      [courseId]: {
        ...courseUserData,
        topics: updatedTopics,
      },
    };

    await updateDoc(userRef, { courses: updatedCourses }).then(()=>console.log(taskId));
    
    dispatch(setUser({
      user: {
        ...user,
        courses: updatedCourses,
      },
    }));
    console.log("Task updated or added successfully!");
  } else {
    console.log("User document does not exist");
  }
}
