import React, { useEffect, useState } from 'react';
import { ref, getDownloadURL, getStorage } from 'firebase/storage';

interface ImageViewerProps {
    filePath: string;
    onComplete: () => void;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ filePath, onComplete }) => {
    const storage = getStorage();
    const [imageUrl, setImageUrl] = useState<string>('');
    const [blobUrl, setBlobUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchFile = async () => {
            try {
                setLoading(true);
                // Отримання URL для завантаження файлу
                const fileRef = ref(storage, filePath);
                const url = await getDownloadURL(fileRef);

                // Завантаження зображення як Blob
                const response = await fetch(url);
                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);

                setImageUrl(url);
                setBlobUrl(blobUrl);
                setLoading(false);
              
            } catch (error) {
                console.error('Error fetching image', error);
                setLoading(false);
            }
        };

        // Викликати функцію лише якщо filePath не порожній
        if (filePath) {
            fetchFile();
        }
    }, [filePath]); 

    useEffect(() => {
        setTimeout(() => {
            onComplete();
          }, 100);
    }, []); 

    const handleDownload = () => {
        if (blobUrl) {
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = 'image.jpg'; // Вкажіть назву зображення
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <div>
            {loading ? (
                <></>
            ) : (
                imageUrl ?  <>
                    <img
                        src={imageUrl}
                        alt="Uploaded"
                        className="w-full h-auto rounded-md mb-6"
                    />
                    <button
                        className="flex gap-2 hover:bg-slate-200 w-fit p-3 rounded-lg bg-white"
                        onClick={handleDownload}
                    >
                        Скачати Зображення
                        <svg
                            className="h-6"
                            data-name="Livello 1"
                            id="Livello_1"
                            viewBox="0 0 128 128"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title />
                            <path d="M61.88,93.12h0a3,3,0,0,0,.44.36l.24.13a1.74,1.74,0,0,0,.59.24l.25.07h0a3,3,0,0,0,1.16,0l.26-.08.3-.09a3,3,0,0,0,.3-.16l.21-.12a3,3,0,0,0,.46-.38L93,66.21A3,3,0,1,0,88.79,62L67,83.76V3a3,3,0,0,0-6,0V83.76L39.21,62A3,3,0,0,0,35,66.21Z" />
                            <path d="M125,88a3,3,0,0,0-3,3v22a9,9,0,0,1-9,9H15a9,9,0,0,1-9-9V91a3,3,0,0,0-6,0v22a15,15,0,0,0,15,15h98a15,15,0,0,0,15-15V91A3,3,0,0,0,125,88Z" />
                        </svg>
                    </button>
                </> :
                <></>
            )}
        </div>
    );
};

export default ImageViewer;
