import React, { useState, useCallback, useEffect, useRef } from 'react';
import DisplayHtmlContentWithInputs from './DisplayHtmlContentWithInputs';
import { updateSubtopicTask } from '../../utils/updateSubtopicTask';
import { useDispatch } from 'react-redux';

function DisplayComponent({ htmlContent, answers, user, topicId, subtopicId, taskId, courseId, onComplete }: any) {
  const [results, setResults] = useState<boolean[]>(new Array(answers.length).fill(false));
  const [userInputs, setUserInputs] = useState<string[]>(new Array(answers.length).fill(''));
  const [isChecked, setIsChecked] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null); // Створюємо реф для контейнера

  // Функція для перевірки відповідей
  const handleCheck = useCallback(() => {
    const inputs = containerRef.current?.querySelectorAll<HTMLInputElement>('input[data-id]');

    const newResults = Array.from(inputs || []).map((input, index) => {
      // Видаляємо пробіли та пунктуацію (крапки, коми, знаки питання, тощо) перед перевіркою
      const cleanText = (text: string) =>
        text.replace(/[\s.,?!:;()'"]/g, '').toLowerCase(); // Видаляємо зайві символи і знижуємо регістр

      // Розділяємо правильну відповідь на дві частини, якщо є знак '='
      const possibleAnswers = answers[index].split('=').map((answer: string) => cleanText(answer));

      const userAnswer = cleanText(input.value);

      // Перевіряємо, чи введена користувачем відповідь відповідає одній з можливих відповідей
      const isCorrect = possibleAnswers.some((correctAnswer: string) => correctAnswer === userAnswer);

      return isCorrect;
    });

    const newUserInputs = Array.from(inputs || []).map(input => input.value);
    setResults(newResults);
    setUserInputs(newUserInputs);
    setIsChecked(true);
    setIsTaskCompleted(true);
  }, [answers]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user) {
          const courseUserData = user.courses?.[courseId];
          if (courseUserData) {
            const topic = courseUserData.topics?.find((t: any) => t.id === topicId);
            if (topic) {
              const subtopic = topic.subtopics?.find((s: any) => s.id === subtopicId);
              if (subtopic) {
                const task = subtopic.tasks?.find((t: any) => t.id === taskId);
                if (task && task.userInputs) {
                  setUserInputs(task.userInputs);
                  setIsTaskCompleted(task.isCompleted);
                }
              }
              setIsFetched(true);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [user, courseId, topicId, subtopicId, taskId]);

  useEffect(() => {
    if (isTaskCompleted) {
      const inputs = containerRef.current?.querySelectorAll<HTMLInputElement>('input[data-id]');

      const cleanText = (text: string) =>
        text.replace(/[\s.,?!:;()'"]/g, '').toLowerCase(); // Видаляємо зайві символи і знижуємо регістр

      const newResults = Array.from(inputs || []).map((input, index) => {
        // Розділяємо правильну відповідь на дві частини, якщо є знак '='
        const possibleAnswers = answers[index].split('=').map((answer: string) => cleanText(answer));

        const userAnswer = cleanText(input.value);

        // Перевіряємо, чи введена користувачем відповідь відповідає одній з можливих правильних відповідей
        const isCorrect = possibleAnswers.some((correctAnswer: string) => correctAnswer === userAnswer);

        return isCorrect;
      });

      setResults(newResults);
    }
  }, [isTaskCompleted, answers]);

  // Збереження даних у Firebase після перевірки відповідей
  useEffect(() => {
    if (isChecked) {
      updateSubtopicTask(user, courseId, topicId, subtopicId, taskId, true, userInputs, dispatch)
        .then(() => {
          console.log('Answers saved successfully!');
        })
        .catch(error => {
          console.error('Error saving answers:', error);
        });

      setIsChecked(false);
    }
  }, [isChecked, userInputs, results, user, topicId, subtopicId, taskId, courseId]);

  useEffect(() => {
    const updateTask = async () => {
      if (isFetched) {
        await updateSubtopicTask(user, courseId, topicId, subtopicId, taskId, isTaskCompleted ?? false, userInputs, dispatch);
        onComplete();
      }
    };
    updateTask();
  }, [isFetched]);

  return (
    <>
      <div className='flex w-full' ref={containerRef}> {/* Прив’язуємо реф до контейнера */}
        <DisplayHtmlContentWithInputs
          content={htmlContent}
          results={results}
          userInputs={userInputs}
          isTaskCompleted={isTaskCompleted}
          answers={answers} // Передаємо правильні відповіді як пропс
        />
      </div>
      <div className='flex justify-center'>
        <button className='p-3 text-base rounded-2xl bg-green-400/60 hover:bg-green-400' onClick={handleCheck}>
          Перевірити відповіді
        </button>
      </div>
    </>
  );
};

export default DisplayComponent;
